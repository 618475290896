import React from 'react'
import useStore from '___store'

// import { AI } from 'assets/svgIconComponents'
import { ButtonGroup, Button } from 'components'
// import useAIQuestion from './useAIQuestion'
// import MarkerAssign from './MarkerAssign'
import { classes } from '../..'
// import StaticCalculation from './StaticCalculation'
import Attributes from './Marker.Attributes'
import MarkerConnect from './Marker.Connect'
import MarkerOutput from './Marker.Output'
import Mapping from './Marker.Mapping'
import Formatting from './Marker.Formatting'

const MarkerConfiguration = React.memo(({ id }) => {
  const {
    // textMarkerLocations,
    locationById: { type, label, color, combine, concatString, calculation, valueMap } = {},
    // currentConfiguringQuestion: { id: currentQuestionId } = {},
    concludeConfiguring,
    unwrapMarker,
  } = useStore(
    // 'selectTextMarkerLocations',
    `selectLocationById[${id}]`,
    // 'selectCurrentConfiguringQuestion',
    'concludeConfiguring',
    'unwrapMarker'
  )

  // const { mutate: createQuestion } = useAIQuestion(id, contentText)

  const {
    footer: footerClass,
    markerConfiguration: { wrapper: wrapperClass },
  } = classes.configEntry

  // const relevantParagraphIds = Object.entries(textMarkerLocations).reduce((result, [id, markerArray]) => {
  //   const foundDuplicates = markerArray.filter(({ contentText: ct }) => ct === contentText)
  //   if (foundDuplicates.length) result.push(id)
  //   return result
  // }, [])

  // const { segments } = useStore('selectSegments')

  // const parseParagraphs = (segments = []) =>
  //   segments
  //     .reduce((accumulated, current) => {
  //       const { id, type, textChunks = [] } = current
  //       if (type === 'table')
  //         return accumulated.concat(
  //           ['header', 'footer', 'body'].reduce(
  //             (combinedSections, section) =>
  //               (current[section] || []).reduce(
  //                 (combinedRows, { cells = [] }) =>
  //                   cells.reduce((combinedCells, { content }) => combinedCells.concat(parseParagraphs(content)), combinedRows),
  //                 combinedSections
  //               ),
  //             []
  //           )
  //         )
  //       if (type === 'paragraph') return accumulated.concat({ id, text: textChunks.map(({ text }) => text).join('') })
  //       return accumulated
  //     }, [])
  //     .filter(({ text }) => text.length)
  // const parsedParagraphs = parseParagraphs(segments || [])

  const deleteMarker = ({ id }) => {
    unwrapMarker({ id })
    concludeConfiguring()
  }

  // const body = {
  //   contexts: parsedParagraphs.reduce((acc, cur) => acc.concat(relevantParagraphIds.includes(cur.id) ? cur.text : []), []),
  //   placeholder: contentText,
  // }

  return (
    <>
      <div className={wrapperClass}>
        {/* {type === 'text' ? (
          <div className={classes.configEntry.markerConfiguration.fragment.wrapper}>
            <span className={classes.configEntry.markerConfiguration.fragment.title}>AI</span>
            <ButtonGroup>
              <Button
                className={classes.configEntry.markerConfiguration.fragment.generateQuestionButton}
                onClick={() => createQuestion(body)}
                colorScheme="primary"
              >
                Generate Question with AI
                <AI />
              </Button>
            </ButtonGroup>
          </div>
        ) : null} */}
        {/* <MarkerAssign markerId={id} assignedQuestionId={questionId} currentQuestionId={currentQuestionId} assignedOptionIds={optionIds} /> */}
        <MarkerConnect id={id} />
        <MarkerOutput id={id} type={type} combine={combine} concatString={concatString} calculation={calculation} />
        {/* <StaticCalculation id={id} type={type} /> */}
        <Attributes id={id} label={label} color={color} concatString={concatString} />
        <Mapping id={id} map={valueMap} />
        <Formatting id={id} />
      </div>
      <div className={footerClass}>
        <ButtonGroup>
          <Button onClick={() => deleteMarker({ id })} colorScheme="error">
            Delete Marker
          </Button>
          <Button onClick={concludeConfiguring} colorScheme="primary">
            Conclude
          </Button>
        </ButtonGroup>
      </div>
    </>
  )
})

MarkerConfiguration.displayName = 'Wizard-Configuration-MarkerConfiguration'

export default MarkerConfiguration
