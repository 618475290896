import useStore, { UpdateQuestionConditionAction, WizardDependentsByIdSelector, WizardQuestionsSelector } from '___store'
import { Option, QuestionConditionRule } from '___types'
import { Input, Select } from 'components/CasusComponents'
import React, { FunctionComponent, useMemo } from 'react'

type UseStoreHookResultType = {
  wizardQuestions: WizardQuestionsSelector
  wizardDependentsById: WizardDependentsByIdSelector
  updateQuestionCondition: UpdateQuestionConditionAction
}
export const Question: FunctionComponent<{ questionId: string; groupId: string; id: string; condition: QuestionConditionRule }> = React.memo(
  ({ questionId, groupId, id, condition }) => {
    const { wizardQuestions, wizardDependentsById, updateQuestionCondition } = useStore(
      'selectWizardQuestions',
      `selectWizardDependentsById[${questionId}]`,
      'updateQuestionCondition'
    ) as UseStoreHookResultType

    const filteredQuestions = useMemo(
      () =>
        wizardQuestions?.filter(
          ({ id }) =>
            !wizardDependentsById
              .split(';')
              .reduce(
                (result, dependencyString) => {
                  const [type, id] = dependencyString.split(':')
                  return result.concat(type === 'question' ? id : [])
                },
                [questionId] as string[]
              )
              .includes(id)
        ),
      [wizardQuestions, wizardDependentsById, questionId]
    )

    const relevantQuestion = useMemo(() => filteredQuestions?.find(({ id }) => id === condition.question), [filteredQuestions, condition.question])

    const options = useMemo(
      () => (relevantQuestion?.optionGroups || []).reduce((result, { options }) => result.concat(options), [] as Option[]),
      [relevantQuestion]
    )

    return (
      <div>
        <Select
          value={condition?.question}
          options={(filteredQuestions || []).reduce((result, { id, text }) => Object.assign(result, { [id]: text }), {})}
          onSelect={selectedId => updateQuestionCondition({ questionId, groupId, id, condition: { question: selectedId } })}
        />
        {relevantQuestion ? (
          <>
            <Select
              value={condition?.instance ?? 'any'}
              options={{ any: 'Any instance', all: 'All instances', specific: 'Specific instance', current: 'Current instance' }}
              onSelect={instance => updateQuestionCondition({ questionId, groupId, id, condition: { instance } })}
            />
            {condition?.instance === 'specific' ? (
              <Input
                type="number"
                value={condition?.instanceIndex}
                onInput={event =>
                  updateQuestionCondition({ questionId, groupId, id, condition: { instanceIndex: Number((event.target as HTMLInputElement).value) } })
                }
              />
            ) : null}
            <Select
              value={condition?.option}
              options={options.reduce(
                (result, { id, text, value, placeholder }) => Object.assign(result, { [id]: text || value || placeholder || 'User input' }),
                {}
              )}
              onSelect={selectedId => updateQuestionCondition({ questionId, groupId, id, condition: { option: selectedId } })}
            />
          </>
        ) : null}
      </div>
    )
  }
)

Question.displayName = 'Question-ConditionalConfiguration-ConditionGroup-Condition-Question'

export default Question
