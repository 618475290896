import React, { FunctionComponent, useMemo } from 'react'
import useStore, { AddQuestionConditionAction, RemoveQuestionConditionGroupAction, ToggleQuestionConditionGroupLogicAction } from '___store'

import { LOGIC, QuestionConditionGroup as GroupType, QuestionConditionRule } from '___types'
import { Bin, Plus } from 'assets/svgIconComponents'
import { Button, Switch } from 'components/CasusComponents'
import { conditionGroupClasses as classes } from '.'
import QuestionConditionComponent from './QuestionCondition'

type UseStoreHookResultType = {
  toggleQuestionConditionGroupLogic: ToggleQuestionConditionGroupLogicAction
  removeQuestionConditionGroup: RemoveQuestionConditionGroupAction
  addQuestionCondition: AddQuestionConditionAction
}

export const QuestionConditionGroup: FunctionComponent<{
  questionId: string
  id: string
  group: GroupType
}> = React.memo(({ questionId, id, group }) => {
  const { toggleQuestionConditionGroupLogic, removeQuestionConditionGroup, addQuestionCondition } = useStore(
    'toggleQuestionConditionGroupLogic',
    'removeQuestionConditionGroup',
    'addQuestionCondition'
  ) as UseStoreHookResultType

  const conditions = useMemo(() => {
    const conditions = Object.assign({}, group) as Record<string, QuestionConditionRule>
    delete conditions.logic
    return conditions
  }, [group])

  const render = useMemo(
    () =>
      Object.entries(conditions).map(([conditionId, condition]) => (
        <QuestionConditionComponent
          key={`Question-${questionId}-ConditionalConfiguration-ConditionGroup-${id}-Condition-${conditionId}`}
          questionId={questionId}
          groupId={id}
          id={conditionId}
          condition={condition}
        />
      )),
    [conditions, questionId, id]
  )

  return (
    <div className={classes.wrapper}>
      Conditions: {render}
      <div className={classes.actions}>
        <Button onClick={() => addQuestionCondition({ questionId, groupId: id })} blue>
          <Plus />
          Add condition
        </Button>
        <Switch value={group.logic === LOGIC.OR} onClick={() => toggleQuestionConditionGroupLogic({ questionId, groupId: id })} pin={group.logic} />
        <Button onClick={() => removeQuestionConditionGroup({ questionId, groupId: id })} red>
          <Bin />
          Remove condition group
        </Button>
      </div>
    </div>
  )
})

QuestionConditionGroup.displayName = 'Question-ConditionalConfiguration-ConditionGroup'

export default QuestionConditionGroup
