import { ReadonlyRecord, TupleToUnion, ValuesOf } from '.'

export const CASUS_PREFIX = '_casus_'

export const CASUS_KEYSTRINGS = {
  REPLACE: `${CASUS_PREFIX}replace`,
  INPUT: `${CASUS_PREFIX}input`,
  PREDEFINED: `${CASUS_PREFIX}predefined`,
  COMPUTED: `${CASUS_PREFIX}computed`,
  INTEGRATION: `${CASUS_PREFIX}integration`,
  NUMBERING: `${CASUS_PREFIX}numbering`,
  NUMBERING_SYSTEM_KEY: `${CASUS_PREFIX}numbering_system_key`,
  NUMBERING_LEVEL: `${CASUS_PREFIX}numbering_level`,
  LINK: `${CASUS_PREFIX}link`,
  REFERENCE: `${CASUS_PREFIX}reference`,
  //   REMOVE: `${CASUS_PREFIX}remove`,
  //   KEEP: `${CASUS_PREFIX}keep`,
  //   HIGHLIGHT: `${CASUS_PREFIX}highlight`,
} as const
// type CasusKeystrings = typeof CASUS_KEYSTRINGS
// type CasusKeystringsKeys = keyof CasusKeystrings
// type CasusKeystring = CasusKeystrings[CasusKeystringsKeys]

export const CASUS_IDS = {
  DATASTRUCTURE_ID: `${CASUS_PREFIX}data_structure`,
  //   ROOT_ELEMENT: `${CASUS_PREFIX}root_element`,
} as const
// type CasusIds = typeof CASUS_IDS
// type CasusIdsKeys = keyof CasusIds
// type CasusId = CasusIds[CasusIdsKeys]

// const CASUS_CLASSES = {
//   SECTION: `${CASUS_PREFIX}section_root`,
//   PAGE_CONTENT_ROOT: `${CASUS_PREFIX}page_content_root`,
//   SEGMENTS_MARKER_DIV: `${CASUS_PREFIX}segments_marker_div`,
//   SEGMENTS_MARKER_HEADER: `${CASUS_PREFIX}segments_marker_header`,
//   SEGMENTS_MARKER_CONTENT: `${CASUS_PREFIX}segments_marker_content`,
//   TEXT_MARKER: `${CASUS_PREFIX}text_marker`,
//   TEXT_MARKER_CONTROLS: `${CASUS_PREFIX}text_marker_controls`,
//   PARAGRAPH_SEGMENT: `${CASUS_PREFIX}paragraph_segment`,
//   TABLE_SEGMENT: `${CASUS_PREFIX}table_segment`,
//   TABLE_HEADER: `${CASUS_PREFIX}table_header`,
//   TABLE_BODY: `${CASUS_PREFIX}table_body`,
//   TABLE_FOOTER: `${CASUS_PREFIX}table_footer`,
//   TABLE_ROW: `${CASUS_PREFIX}table_row`,
//   TABLE_CELL: `${CASUS_PREFIX}table_cell`,
//   TEXT_CHUNK: `${CASUS_PREFIX}text_chunk_span`,
// } as const
// type CasusClasses = typeof CASUS_CLASSES
// type CasusClassesKeys = keyof CasusClasses
// type CasusClass = CasusClasses[CasusClassesKeys]

export const FULL_RANGE = [0, +Infinity] as const

const PAPER_NAMES = {
  LETTER: 'letter',
  LEGAL: 'legal',
  A3: 'a3',
  A4: 'a4',
  A5: 'a5',
  B4: 'b4',
  B5: 'b5',
  EURO_FANFOLD: 'euro-fanfold',
  EXECUTIVE1: 'executive1',
  EXECUTIVE2: 'executive2',
  EXECUTIVE3: 'executive3',
  FOLIO: 'folio',
  LEDGER: 'ledger',
  TABLOID: 'tabloid',
  QUATRO: 'quatro',
  SHORT: 'short',
  STATEMENT: 'statement',
  STATIONERY: 'stationery',
  NARROW: 'narrow',
  WIDE: 'wide',
  '1403W': '1403w',
  '1403WS': '1403ws',
  '3800N': '3800n',
  '3800NS': '3800ns',
  '3800W': '3800w',
  '3800WS': '3800ws',
  CUT_SHEET: 'cut-sheet',
} as const
type PaperNames = typeof PAPER_NAMES
type PaperNamesKeys = keyof PaperNames
export type PaperName = PaperNames[PaperNamesKeys]

const PAGE_ORIENTATIONS = {
  HORIZONTAL: 'landscape',
  VERTICAL: 'portrait',
} as const
type PageOrientations = typeof PAGE_ORIENTATIONS
type PageOrientationsKeys = keyof PageOrientations
type PageOrientation = PageOrientations[PageOrientationsKeys]

const MMPI = 25.4 as const
export const PXPI = 96 as const
const PAGE_SIZES_IMPERIAL = {
  [PAPER_NAMES.A5]: { width: 148 / MMPI, height: 210 / MMPI },
  [PAPER_NAMES.B5]: { width: 182 / MMPI, height: 257 / MMPI },
  [PAPER_NAMES.A4]: { width: 210 / MMPI, height: 297 / MMPI },
  [PAPER_NAMES.QUATRO]: { width: 215 / MMPI, height: 275 / MMPI },
  [PAPER_NAMES.EURO_FANFOLD]: { width: 250 / MMPI, height: 340 / MMPI },
  [PAPER_NAMES.B4]: { width: 257 / MMPI, height: 364 / MMPI },
  [PAPER_NAMES.A3]: { width: 297 / MMPI, height: 420 / MMPI },
  [PAPER_NAMES.STATEMENT]: { width: 5.5, height: 8.5 },
  [PAPER_NAMES.EXECUTIVE1]: { width: 7, height: 10.5 },
  [PAPER_NAMES.EXECUTIVE2]: { width: 7.25, height: 10.5 },
  [PAPER_NAMES.EXECUTIVE3]: { width: 7.5, height: 10.5 },
  [PAPER_NAMES['3800NS']]: { width: 7.5, height: 11.5 },
  [PAPER_NAMES['3800WS']]: { width: 7.5, height: 13.5 },
  [PAPER_NAMES.STATIONERY]: { width: 8, height: 10 },
  [PAPER_NAMES['3800N']]: { width: 8.5, height: 10 },
  [PAPER_NAMES.SHORT]: { width: 8.5, height: 10.5 },
  [PAPER_NAMES.LETTER]: { width: 8.5, height: 11 },
  [PAPER_NAMES.FOLIO]: { width: 8.5, height: 13 },
  [PAPER_NAMES['1403WS']]: { width: 8.5, height: 13.5 },
  [PAPER_NAMES.LEGAL]: { width: 8.5, height: 14 },
  [PAPER_NAMES['3800W']]: { width: 10, height: 13.5 },
  [PAPER_NAMES.NARROW]: { width: 10, height: 14 },
  [PAPER_NAMES['1403W']]: { width: 11, height: 13.5 },
  [PAPER_NAMES.WIDE]: { width: 11, height: 14 },
  [PAPER_NAMES.TABLOID]: { width: 11, height: 17 },
  [PAPER_NAMES.LEDGER]: { width: 17, height: 11 },
  [PAPER_NAMES.CUT_SHEET]: { width: 17, height: 22 },
} as const
type PageSizesImperial = typeof PAGE_SIZES_IMPERIAL
type PageSizeImperial = PageSizesImperial[PaperName]
type PageSize = { readonly width: number; readonly height: number }

const getPageSizeImperial = (paperName: PaperName): PageSizeImperial => PAGE_SIZES_IMPERIAL[paperName]
const convertImperialToPixels = (inches: number): number => inches * PXPI
const convertImperialPageSizeToPixels = (pageSize: PageSize): PageSize => ({
  width: convertImperialToPixels(pageSize.width),
  height: convertImperialToPixels(pageSize.height),
})
export const PAGE_SIZES = (Object.values(PAPER_NAMES) as PaperName[]).reduce(
  (acc, paper) => Object.assign(acc, { [paper]: convertImperialPageSizeToPixels(getPageSizeImperial(paper)) }),
  {}
) as ReadonlyRecord<PaperName, PageSize>

const DEFAULT_PAGE_MARGINS = { top: 1.25, left: 1, bottom: 0.75, right: 1 } as const
export type PaperMargin = { top?: number; left?: number; bottom?: number; right?: number }
export const DEFAULT_PAGE_LAYOUT = { orientation: PAGE_ORIENTATIONS.VERTICAL, paper: PAPER_NAMES.A4, margins: DEFAULT_PAGE_MARGINS } as const
export type PageLayout = { orientation: PageOrientation; paper: PaperName; margins: PaperMargin }

export const CUSTOM_TEXT_PROPERTIES = {
  valueSource: ['type', 'value'],
  optionValue: ['type', 'value', 'instance'],
  markerReplace: ['type', 'value'],
  casusLink: ['text', 'href'],
  reference: ['sourceIndex', 'valueIndex'],
} as const
export type CustomTextType = keyof typeof CUSTOM_TEXT_PROPERTIES
export type ValueSourceProperties = { [K in TupleToUnion<(typeof CUSTOM_TEXT_PROPERTIES)['valueSource']>]?: string }
export type OptionValueProperties = { [K in TupleToUnion<(typeof CUSTOM_TEXT_PROPERTIES)['optionValue']>]?: string }
export type MarkerReplaceProperties = { [K in TupleToUnion<(typeof CUSTOM_TEXT_PROPERTIES)['markerReplace']>]?: string }
export type CasusLinkProperties = { [K in TupleToUnion<(typeof CUSTOM_TEXT_PROPERTIES)['casusLink']>]?: string }
export type ReferenceProperties = { [K in TupleToUnion<(typeof CUSTOM_TEXT_PROPERTIES)['reference']>]?: string }
export const extractPropertiesFromCustomText = <T extends CustomTextType>(
  customText: string,
  type: T
): { [K in TupleToUnion<(typeof CUSTOM_TEXT_PROPERTIES)[T]>]?: string } => {
  const joinedKeystrings = Object.values(CASUS_KEYSTRINGS).join('|')
  const customTextMatchRegex = new RegExp(`^{{(${joinedKeystrings})(\\s)*(?<properties>([^{}]|((?<=\\\\){)|((?<=\\\\){))*)}}$`, 'm')
  const joinedPropertyNames = CUSTOM_TEXT_PROPERTIES[type].join('|')
  const propertiesMatchAllRegex = new RegExp(
    `(?<property>((\\s)*(?<propertyName>(${joinedPropertyNames}))(\\s)*=(\\s)*)(?<propertyValue>("([^"]|((?<=\\\\)"))*")|('([^']|((?<=\\\\)'))*')))`,
    'gm'
  )
  return Array.from(customText?.match(customTextMatchRegex)?.groups?.properties?.matchAll(propertiesMatchAllRegex) || []).reduce(
    (result, { groups: { propertyName, propertyValue } = {} }) =>
      propertyName ? Object.assign(result, { [propertyName]: propertyValue.slice(1, -1) }) : result,
    {}
  )
}

export const UUID_REPLACE_ALL = new RegExp('(^[0-9]+(-[0-9]+)*)|-', 'g')
export const NEW_LINE_MATCH = new RegExp('\\n', 'g')
export const BREAK_TAG_MATCH = new RegExp('<br>', 'g')
export const OPERATION_REGEX_STRING = '\\s*[\\^*\\/+-]\\s*'
export const NUMBER_REGEX_STRING = '\\s*((\\.[0-9]+)|([0-9]+(\\.[0-9]+)?))\\s*'
export const CUSTOM_TEXT_REGEX_STRING = '\\s*{{[^{}]*}}\\s*'
export const CUSTOM_TEXT_SPLIT = new RegExp('(?=(?<!\\\\){{)|(?<=(?<!\\\\)}})', 'm')
// export const CASUS_LINK_MATCH = new RegExp(`^{{${CASUS_KEYSTRINGS.LINK}(\\s)*(?<properties>([^{}]|\\{|\\})*)}}$`, 'm')
// export const CASUS_LINK_PROPERTIES = ['text', 'href'] as const
// const casusLinkProperties = CASUS_LINK_PROPERTIES.join('|')
// export const CASUS_LINK_PROPERTY_MATCH_ALL = new RegExp(
//   `(?<property>((\\s)*(?<propertyName>${casusLinkProperties})(\\s)*=(\\s)*)(?<propertyValue>("([^"]|((?<=\\\\)"))*")|('([^']|((?<=\\\\)'))*')))`,
//   'gm'
// )

//@ts-ignore
export const removeEscapeCharactersFromString = (string: string) => string.replaceAll('\\', '')

export type Time = { _seconds: number; _nanoseconds: number }
export type Author = { id: string; email: string; firstName: string; lastName: string; imageUrl: string }
export type Signee = { email: string; firstName: string; lastName: string }
export const SECURITY_LEVELS = { BASE: 'SES', HIGH: 'AES', MAX: 'QES' } as const
export type SignatureSecurityLevel = ValuesOf<typeof SECURITY_LEVELS>
// export const SECURITY_LEVEL_MAP = { [SECURITY_LEVELS.BASE]: 'SES', [SECURITY_LEVELS.HIGH]: 'AES', [SECURITY_LEVELS.MAX]: 'QES' } as const

export type BEFolder = {
  authorId: string
  created: Time
  edited: Time
  id: string
  name: string
  parentCategoryId: string
  sharedWith: string[]
  sharingEnabled: boolean
  status: string

  mutating?: boolean
  mutation?: 'create' | 'update' | 'remove'
}
export type PartialBEFolder = { [K in keyof BEFolder]?: BEFolder[K] }
export type Folder = {
  id?: string
  label: string
  children: Record<string, Folder>
  actions?: Record<string, { label: string; icon: JSX.Element; callback: () => void }>[]
  mutating?: boolean
  mutation?: 'create' | 'update' | 'remove'
}
