import React, { FunctionComponent, RefCallback, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Signee, SignatureSecurityLevel } from '___types'
import { Button, Input } from 'components/CasusComponents'
import { ModalContentProps, modalContentClasses as classes } from '.'
import Security from './Signature.Security'
import Signees from './Signature.Signees'

export const ModalContent: FunctionComponent<ModalContentProps> = React.memo(({ level, mailList = [], conclude, context }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'components.signature' })
  const [security, setSecurity] = useState<SignatureSecurityLevel | null>(level || ((context?.security as SignatureSecurityLevel) ?? null))
  const initialSignees = mailList
    .concat((context?.signees as Signee[]) ?? [])
    .reduce(
      ([signees, found], signee) =>
        (found.includes(signee.email) ? [signees, found] : [signees.concat(signee), found.concat(signee.email)]) as [Signee[], string[]],
      [[], []] as [Signee[], string[]]
    )[0]
  const [signees, setSignees] = useState(initialSignees)
  // const securityRef: RefCallback<string | null> = useCallback(node => node && setSecurity(node), [])

  const [messageInput, setMessageInput] = useState<HTMLInputElement>()
  const messageInputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setMessageInput(node), [])

  return (
    <>
      <div className={classes.wrapper}>
        <Security level={security} onSelect={setSecurity} locked={Boolean(level)} />
        <Signees signees={signees} onChange={setSignees} locked={mailList.map(({ email }) => email)} />
        <div className={classes.message}>
          <Input
            ref={messageInputRef}
            type="multiline"
            defaultValue={(context?.message as string) ?? ''}
            placeholder={translate('signature-message-placeholder')}
          />
        </div>
      </div>
      <Button
        className={classes.footer}
        onClick={() => conclude(security, signees, messageInput)}
        disabled={context?.concludeDisabled !== false && !(security && signees.length)}
        primary
      >
        {(context?.concludeText as String) ?? translate('request-signatures')}
      </Button>
    </>
  )
})

ModalContent.displayName = 'Casus-Components-Signature-ModalContent'

export default ModalContent
