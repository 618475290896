import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Security } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { LevelButtonProps, levelButtonClasses as classes } from '.'

export const LevelButton: FunctionComponent<LevelButtonProps> = React.memo(props => {
  const { level, onClick, disabled } = props
  const { t: translate } = useTranslation('translation', { keyPrefix: 'components.signature' })

  return (
    <Button className={classes.wrapper} onClick={onClick ?? (() => {})} onClickBlur dataSet={{ level: level }} disabled={disabled} transparent>
      <Security level={level} />
      <span className={classes.title}>{translate(level)}</span>
      <span className={classes.description}>{translate(`${level}-description`)}</span>
    </Button>
  )
})

LevelButton.displayName = 'Casus-Components-Signature-Security-LevelButton'

export default LevelButton
