import { useMemo } from 'react'
import useStore, {
  WizardLocationByTypeAndIdSelector,
  NavigateQuestionnaireToAction,
  WizardQuestionLayoutSelector,
  WizardQuestionsSelector,
} from '___store'

import { LOCATION_TYPES, MarkerKeep, MarkerReplace, TextLocation } from '___types'
import { useEditorContext } from '..'

type UseStoreHookResultType = {
  wizardLocationByTypeAndId: WizardLocationByTypeAndIdSelector
  wizardQuestions: WizardQuestionsSelector
  wizardQuestionLayout: WizardQuestionLayoutSelector
  navigateQuestionnaireTo: NavigateQuestionnaireToAction
}
type UseTextMarkerResultType = {
  color: string
  defaultKeep: boolean
  optionIds: string[]
  keep?: MarkerKeep
  replace?: MarkerReplace
  instancing?: string
  instanceCount: number
  concatString?: string
  questionParents: Record<string, string>
  optionParents: Record<string, string>
  offsetMarkers: TextLocation[]
  valueSources: string[]
  combine: boolean
  calculation?: string
  formatting?: string
}

export const useTextMarker = (id: string, range: [number, number]): UseTextMarkerResultType => {
  const editorContext = useEditorContext()
  const contextChildMarkers = editorContext.locations?.text[id]

  const offsetMarkers = useMemo(() => {
    if (!contextChildMarkers?.length) return []
    return contextChildMarkers.map(marker => ({ type: marker.type, id: marker.id, range: marker.range.map(r => r - range[0]) } as TextLocation))
  }, [contextChildMarkers, range])

  const { wizardLocationByTypeAndId, wizardQuestions, wizardQuestionLayout } = useStore(
    `selectWizardLocationByTypeAndId[${LOCATION_TYPES.TEXT},${id}]`,
    'selectWizardQuestions',
    'selectWizardQuestionLayout'
  ) as UseStoreHookResultType

  const {
    color,
    defaultKeep = true,
    optionIds = [],
    keep,
    replace,
    instancing,
    instanceCount = 1,
    concatString,
    valueSources,
    combine = false,
    calculation,
    formatting,
  } = (wizardLocationByTypeAndId || {}) as TextLocation

  const questionParents = useMemo(
    () =>
      (wizardQuestionLayout || []).reduce(
        (result, layoutGroup) =>
          layoutGroup.questions.reduce((result, questionId) => Object.assign(result, { [questionId]: layoutGroup.id }), result),
        {} as Record<string, string>
      ),
    [wizardQuestionLayout]
  )

  const optionParents = useMemo(
    () =>
      optionIds.reduce((result, optionId) => {
        const id = optionId.split(':')[0]
        const question = wizardQuestions?.find(({ optionGroups }) =>
          optionGroups.some(({ options }) => options.some(({ id: optionId }) => optionId === id))
        )
        return Object.assign(result, question && { [id]: question.id })
      }, {} as Record<string, string>),
    [optionIds, wizardQuestions]
  )

  const useTextMarkerReturn = {
    color,
    defaultKeep,
    optionIds,
    keep,
    replace,
    instancing,
    instanceCount,
    concatString,
    questionParents,
    optionParents,
    offsetMarkers,
    valueSources,
    combine,
    calculation,
    formatting,
  }

  return useTextMarkerReturn
}

export default useTextMarker
