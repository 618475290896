import React, { FunctionComponent, useMemo } from 'react'
import useStore, {
  AddQuestionGroupInstanceAction,
  NavigateQuestionnaireForwardAction,
  // NavigateQuestionnaireToAction
} from '___store'

import { ANSWERING_IDS_MATCH, QuestionLayoutRegularGroup } from '___types'
import { Button } from 'components/CasusComponents'
import {
  wizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupNewInstancePromptClasses as classes,
  WizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupNewInstancePromptProps,
} from 'Layouts/WizardLayout'
import { Check, Cross } from 'assets/svgIconComponents'

type UseStoreHookResultType = {
  questionLayoutGroupById: QuestionLayoutRegularGroup | undefined
  navigateQuestionnaireForward: NavigateQuestionnaireForwardAction
  // navigateQuestionnaireTo: NavigateQuestionnaireToAction
  addQuestionGroupInstance: AddQuestionGroupInstanceAction
}

export const NewInstancePrompt: FunctionComponent<WizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupNewInstancePromptProps> = React.memo(
  ({ id }) => {
    const {
      // isFirst,
      questionLayoutGroupId,
      // instanceIndex,
      // orderString,
      // isLast,
    } = useMemo(() => id?.match(ANSWERING_IDS_MATCH)?.groups || {}, [id])

    const {
      questionLayoutGroupById,
      navigateQuestionnaireForward,
      // navigateQuestionnaireTo,
      addQuestionGroupInstance,
    } = useStore(
      `selectQuestionLayoutGroupById[${questionLayoutGroupId}]`,
      'navigateQuestionnaireForward',
      // 'navigateQuestionnaireTo',
      'addQuestionGroupInstance'
    ) as UseStoreHookResultType

    // const firstQuestionIdInGroup = questionLayoutGroupById?.questions[0]

    // const navigateToString = useMemo(
    //   () =>
    //     `${isFirst ? ':' : ''}${questionLayoutGroupId}:${Number(instanceIndex ?? 0) + 1}:${firstQuestionIdInGroup ?? 'new-instance-prompt'}:${
    //       firstQuestionIdInGroup ? orderString : '??'
    //     }${isLast ? ':' : ''}`,
    //   [isFirst, questionLayoutGroupId, instanceIndex, firstQuestionIdInGroup, orderString, isLast]
    // )

    return (
      <div className={classes.wrapper}>
        <span>{questionLayoutGroupById?.label}</span>
        <span>{questionLayoutGroupById?.instancePrompt ?? 'Would you like another instance?'}</span>
        <div className={classes.actions.wrapper}>
          <Button onClick={navigateQuestionnaireForward}>
            <Cross />
            {questionLayoutGroupById?.negative ?? 'No'}
          </Button>
          <Button
            onClick={() => {
              addQuestionGroupInstance(questionLayoutGroupId)
              // navigateQuestionnaireTo(navigateToString)
            }}
            tertiary
          >
            <Check />
            {questionLayoutGroupById?.affirmative ?? 'Yes'}
          </Button>
        </div>
      </div>
    )
  }
)

NewInstancePrompt.displayName = 'WizardLayout-LeftPane-Document-Questionnaire-QuestionGroup-NewInstancePrompt'

export default NewInstancePrompt
