import React, { FunctionComponent, useMemo } from 'react'
import useStore, { NavigateQuestionnaireToAction, WizardAnswerByIdSelector, WizardAnsweringSelector, WizardQuestionByIdSelector } from '___store'

import { Button } from 'components/CasusComponents'
import { WizardLayoutLeftPaneDocumentQuestionnaireQuestionOverviewListQuestionProps } from 'Layouts/WizardLayout'
import { ANSWER_VALUE_MATCH, ANSWERING_IDS_MATCH } from '___types'

type UseStoreHookResultType = {
  wizardQuestionById: WizardQuestionByIdSelector
  wizardAnswerById: WizardAnswerByIdSelector
  wizardAnswering: WizardAnsweringSelector
  navigateQuestionnaireTo: NavigateQuestionnaireToAction
}

export const ListQuestion: FunctionComponent<WizardLayoutLeftPaneDocumentQuestionnaireQuestionOverviewListQuestionProps> = React.memo(
  ({ id, collapseCallback }) => {
    const { instanceIndex, questionId, orderString } = useMemo(() => id?.match(ANSWERING_IDS_MATCH)?.groups || {}, [id])
    const { wizardQuestionById, wizardAnswerById, wizardAnswering, navigateQuestionnaireTo } = useStore(
      `selectWizardQuestionById[${questionId}]`,
      `selectWizardAnswerById[${questionId}]`,
      'selectWizardAnswering',
      'navigateQuestionnaireTo'
    ) as UseStoreHookResultType
    if (!wizardQuestionById) return null
    const { text } = wizardQuestionById
    const { values } = wizardAnswerById || {}

    const answered = useMemo(
      () => values?.some(value => value.match(ANSWER_VALUE_MATCH)?.groups?.instanceIndex === instanceIndex),
      [values, instanceIndex]
    )

    return (
      <Button
        onClick={() => {
          navigateQuestionnaireTo(id)
          collapseCallback()
        }}
        onClickBlur
        disabled={id === wizardAnswering}
        tertiary={id === wizardAnswering}
      >
        <span data-answered={answered ? '' : undefined}>{orderString}</span>
        <span>{text}</span>
      </Button>
    )
  }
)

ListQuestion.displayName = 'WizardLayout-LeftPane-Document-Questionnaire-QuestionOverview-ListQuestion'

export default ListQuestion
