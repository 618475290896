import React, { FunctionComponent, useMemo } from 'react'

import { OPTION_GROUP_SELECT, OptionGroupSelectUnionType } from '___types'
import Option from './Option'
import {
  WizardLayoutLeftPaneDocumentQuestionnaireOptionGroupProps,
  wizardLayoutLeftPaneDocumentQuestionnaireOptionGroupClasses as classes,
} from 'Layouts/WizardLayout'

const OptionGroup: FunctionComponent<WizardLayoutLeftPaneDocumentQuestionnaireOptionGroupProps> = React.memo(
  ({ instanceIndex, optionGroup, singleGroup, indexOffset = 0, firstGroup }) => {
    const { label, options, select } = optionGroup || {}

    // const isSpecial =
    //   Boolean(label) || (!singleGroup && select?.select === 'multi' && (select.minimum !== 0 || select.maximum !== options?.length)) || undefined
    const render = useMemo(
      () =>
        options?.map((option, i, array) => (
          <Option
            key={`${i}:${option?.id}`}
            instanceIndex={instanceIndex}
            option={option}
            index={indexOffset + i}
            autoConfirm={singleGroup && (select as unknown as OptionGroupSelectUnionType) === OPTION_GROUP_SELECT.SINGLE}
            singleOption={singleGroup && array.length === 1}
            select={select as unknown as OptionGroupSelectUnionType} // remove type conversion when select is fixed to be an object instead of a OptionGroupSelectUnionType string and change the prop to be {select.select}
            firstOption={firstGroup && i === 0}
          />
        )),
      [options, instanceIndex, indexOffset, singleGroup, select, firstGroup]
    )
    return (
      <div className={classes.wrapper} data-special={Boolean(label) || undefined} data-label={label || undefined}>
        {render}
      </div>
    )
  }
)

OptionGroup.displayName = 'WizardLayout-LeftPane-Document-Questionnaire-OptionGroup'

export default OptionGroup
