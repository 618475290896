import QuestionConditionalConfiguration from './QuestionConditionalConfiguration'

import './style.scss'

export const conditionClasses = {
  wrapper: 'Question-ConditionalConfiguration-ConditionGroup-Condition-wrapper',
}
export const conditionGroupClasses = {
  wrapper: 'Question-ConditionalConfiguration-ConditionGroup-wrapper',
  condition: conditionClasses,
  actions: 'Question-ConditionalConfiguration-ConditionGroup-actions',
}
export const classes = {
  wrapper: 'Question-ConditionalConfiguration-wrapper',
  actions: 'Question-ConditionalConfiguration-actions',
  conditionGroup: conditionGroupClasses,
}

export { QuestionConditionalConfiguration }
export default QuestionConditionalConfiguration
