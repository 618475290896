import { FormEvent } from 'react'
import { DateTime } from 'luxon'

import { BEFolder, BREAK_TAG_MATCH, Folder, OPTION_VALUE_TYPES, OptionValueTypeUnionType, RecursiveRecord } from '___types'

type DebounceFunctionType = <T extends (...params: any[]) => any>(callback: T, timeout?: number) => void | ((...params: any[]) => NodeJS.Timeout)
// ) =>  T extends (...params: infer P) => any ? (...params: P) => NodeJS.Timeout : void

export const debounceFunction: DebounceFunctionType = (callback, timeout = 100) => {
  if (typeof callback !== 'function') return
  let debounceTimeout: NodeJS.Timeout
  return (...params: any[]) => {
    window.clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(() => callback(...params), timeout)
    return debounceTimeout
  }
}

//@ts-ignore
export const throttleFunction: <T>(callback: (params?: T) => unknown, interval?: number) => (params?: T) => undefined = (
  callback,
  interval = 100
) => {
  let throttle = false
  return (...params) => {
    if (throttle) return
    throttle = true
    setTimeout(() => {
      callback(...params)
      throttle = false
    }, interval)
  }
}

export const isObject = (object: unknown) => typeof object === 'object' && !Array.isArray(object) && object !== null
export const unnestObjectValues = (object: Record<keyof any, unknown>): unknown[] =>
  Object.values(object).reduce(
    (result: unknown[], value) => result.concat(isObject(value) ? unnestObjectValues(value as Record<keyof any, unknown>) : value),
    []
  )

export const replaceInArray = <T extends any>(array: T[], method: (entry: T) => boolean, payload: T | ((entry: T) => T | any) | any) => {
  const index = array.findIndex(method)
  if (index !== -1) array.splice(index, 1, typeof payload === 'function' ? payload(array[index]) : payload)
  return array
}

export const dataURLToBlob = (dataURL: string) => {
  const binary = atob(dataURL.split(',')[1])
  const array = Array.from(Array(binary.length), (_, i) => binary.charCodeAt(i))
  return new Blob([new Uint8Array(array)], { type: 'image/png' })
}

const injectFromString = (array: HighlightStringResultEntryType[], string: string, index: number, type: 'text' | 'highlight') => {
  const lastEntry = array.length && array[array.length - 1]
  const value = string.slice(index)
  if (value.length) {
    if (lastEntry && lastEntry.type === type) lastEntry.value = `${value}${lastEntry.value}`
    else array.push({ type, value })
    return string.slice(0, index)
  }
  return string
}

export type HighlightStringResultEntryType = { type: 'text' | 'highlight'; value: string }
export const highlightString = (string: string, indices: [number, number][]) => {
  //@ts-ignore
  const reversed = indices.slice().toReversed()
  let mutatedString = string
  return (
    reversed
      .reduce((result: HighlightStringResultEntryType[], [start, end]: [number, number]) => {
        mutatedString = injectFromString(result, injectFromString(result, mutatedString, end, 'text'), start, 'highlight')
        return result
      }, [] as HighlightStringResultEntryType[])
      .concat({ type: 'text', value: mutatedString })
      //@ts-ignore
      .toReversed()
  )
}

export const filterObjectFields = (object: Record<keyof any, unknown>, ...keys: (string[] | string)[]): Record<keyof any, unknown> => {
  const ignoreList = keys.reduce((acc: string[], cur) => acc.concat(cur), []) // merging string params with string[] params
  return Object.entries(object).reduce(
    (result, [k, v]) => (v === undefined || ignoreList.includes(k) ? result : Object.assign(result, { [k]: v })),
    {}
  )
}

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

const VALUE_TYPE_EXTRACT_METHOD_MAP = {
  [OPTION_VALUE_TYPES.STRING]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) => {
    const textarea = document.createElement('textarea')
    textarea.innerHTML =
      //@ts-ignore
      ((event.target as HTMLPreElement)?.innerHTML || (event.target as HTMLInputElement)?.value)?.replaceAll(BREAK_TAG_MATCH, '\n') || ''
    const text = textarea.value
    textarea.remove()
    return text
  },
  [OPTION_VALUE_TYPES.DATE]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) =>
    DateTime.fromFormat((event.target as HTMLInputElement)?.value, 'yyyy-MM-dd')?.toISO() || '',
  [OPTION_VALUE_TYPES.DATE_TIME]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) =>
    DateTime.fromFormat((event.target as HTMLInputElement)?.value, "yyyy-MM-dd'T'HH:mm")?.toISO() || '',
  [OPTION_VALUE_TYPES.NUMBER]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) => Number((event.target as HTMLInputElement)?.value),
  [OPTION_VALUE_TYPES.CURRENCY]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) => (event.target as HTMLInputElement)?.value || '',
}

export const extractValueFromInputEvent = (valueType: OptionValueTypeUnionType, event: FormEvent<HTMLPreElement | HTMLInputElement>) =>
  typeof VALUE_TYPE_EXTRACT_METHOD_MAP[valueType] === 'function'
    ? VALUE_TYPE_EXTRACT_METHOD_MAP[valueType](event)
    : (event.target as HTMLInputElement)?.value || ''

export const generateFolderStructure = (folders: BEFolder[]) => {
  const nestStrings = folders.reduce((result, { id, parentCategoryId }) => {
    const category = Array.isArray(parentCategoryId) ? '' : parentCategoryId || ''
    const prefix = category ? result.find(string => string.split('.').slice(-1)[0] === category) || category : 'mine'
    return result.concat(prefix + `.${id}`).map(string => (string.split('.')[0] === id ? `${prefix}.${string}` : string))
  }, [] as string[])

  const structure = nestStrings.reduce(
    (result, path) => {
      const split = path.split('.')
      let current = result
      while (split.length && split[0] in (current.children || {})) {
        current = current.children[split[0]]
        split.shift()
      }
      Object.assign(
        current.children,
        //@ts-ignore
        split.toReversed().reduce((acc, key) => {
          const listFolder = (key === 'mine' ? { name: 'MINE' } : folders.find(({ id }) => id === key)!) as BEFolder
          const folder = { label: listFolder?.name, children: acc, id: key }
          if (listFolder?.mutating) Object.assign(folder, { mutating: listFolder.mutating, mutation: listFolder.mutation })
          return { [key]: folder }
        }, {} as Record<string, Folder>)
      )
      return result
    },
    { label: 'ROOT', children: {} } as Folder
  )
  return structure
}

export const extractPathFromFolderStructure = (structure: Folder, id: string = '', prefix: string = '') => {
  if (!Object.keys(structure.children || {}).length) return
  let path = id
  if (!(id in structure.children))
    Object.entries(structure.children).some(([key, folder]) => (path = extractPathFromFolderStructure(folder, id, key) || ''))
  return path ? [prefix, path].filter(s => s).join('.') : undefined
}

export const extractValueFromObject: (object: RecursiveRecord<string, unknown>, key: string) => unknown = (object, key) => {
  if (!isObject(object)) return null
  const split = typeof key === 'string' ? key.split('.') : [key]
  return split.length === 1
    ? object[split[0]]
    : extractValueFromObject(object[split[0]] as RecursiveRecord<string, unknown>, split.slice(1).join('.'))
}

type FormatOptions = { dateFormat?: string; dateTimeFormat?: string }
export const getFormattedOptionValue = (valueType: OptionValueTypeUnionType, value: string = '', options?: FormatOptions): string | number => {
  switch (valueType) {
    case 'date': {
      const luxonFromISO = DateTime.fromISO(value)
      if (!luxonFromISO?.isValid) return value
      if (options?.dateFormat) return luxonFromISO?.toFormat(options.dateFormat)
      return !luxonFromISO?.isValid ? value : luxonFromISO?.toFormat('dd.MM.yyyy')
    }
    case 'date-time': {
      const luxonFromISO = DateTime.fromISO(value)
      if (!luxonFromISO?.isValid) return value
      if (options?.dateFormat) return luxonFromISO?.toFormat(options.dateFormat)
      return !luxonFromISO?.isValid ? value : luxonFromISO?.toFormat('dd.MM.yyyy HH:mm')
    }
    case 'number':
      return Number(value)
    default:
      return value || ''
  }
}

export const formatMarkerValue = (value: string, formatting: string): string => {
  const [type, option, details] = formatting.split(':')
  if (type === 'number') {
    if (option === 'trunc') return Number(value).toFixed(Number(details))
    if (option === 'round' && Number(details)) {
      const nearestDecimalCount = details.replace(/^[0-9]+\./, '').length
      return (Math.round(Number(value) / Number(details)) * Number(details)).toFixed(nearestDecimalCount)
    }
  }
  return value
}
