import React from 'react'
import useStore from '___store'

import { ButtonGroup, Button } from 'components'
import { QuestionDetails, QuestionOptions, QuestionText } from './components'
import { classes } from '../../index'

const QuestionConfiguration = React.memo(({ id }) => {
  const { setConfiguring, concludeConfiguring } = useStore('setConfiguring', 'concludeConfiguring')

  const {
    footer: footerClass,
    questionConfiguration: { wrapper: wrapperClass },
  } = classes.configEntry

  return (
    <>
      <div className={wrapperClass}>
        <QuestionText questionId={id} />
        <QuestionOptions questionId={id} />
        <QuestionDetails questionId={id} />
      </div>
      <div className={footerClass}>
        <ButtonGroup>
          <Button onClick={() => setConfiguring({ key: 'question-conditional', id })}>Conditional Logic</Button>
          <Button onClick={concludeConfiguring} colorScheme="primary">
            Conclude
          </Button>
        </ButtonGroup>
      </div>
    </>
  )
})

QuestionConfiguration.displayName = 'Wizard-Configuration-QuestionConfiguration'

export default QuestionConfiguration
