import { IPermissions, ITemplate, ITemplateExtended, IUpdateTemplateApiData, Question, TemplateType } from 'types'
import Api from './api'
import { parseBEData } from 'utilities'
import { parseV3forBE, v3parse } from 'Wizard/parsing'
// import { SECURITY_LEVEL_MAP } from '___types'

export interface ICreateTemplateApi {
  category?: string[]
  questions?: Question[]
  htmlData?: string
  originalStylesKept?: boolean
  base64data?: string
  hyphens?: boolean
  name?: string
  filename?: string
  new?: boolean
  isAutoAutomateAI?: boolean
}

export interface ISaveQuestionsApi {
  htmlData: string
  questions: Question[]
}

export interface IGetTemplatesApi {
  type: TemplateType
  lastItemFetched?: string
  limit?: number
  folderId?: string
}

export interface IUpdateTemplateApi {
  id: string
  name?: string
  data: IUpdateTemplateApiData
  new?: boolean
  version?: 'v3'
}

export interface IDuplicateTemplateApi {
  id: string
}

export interface IUploadTemplateApi {
  base64data: string
  originalStylesKept: boolean
}

class TemplateApi extends Api {
  public constructor() {
    super('/templates', true)
  }

  public create = async (data: ICreateTemplateApi): Promise<ITemplate> => {
    const isEmpty = !Object.keys(data).filter(e => e !== 'new').length
    let res = {} as any
    if (!isEmpty && data.new) {
      const response = await this.api().post('/v2/templates/upload/docx', {
        base64data: data.base64data,
      })
      res = await this.api().post('/v2/templates', {
        category: data.category ?? [],
        originalStylesKept: data.originalStylesKept ?? false,
        questions: data.questions ?? [],
        storageId: String(response.data.data.storageId) || '',
        hyphens: data.hyphens ?? false,
        name: data.name ?? 'Unbenannte Vorlage',
        isAutoAutomateAI: data.isAutoAutomateAI,
      })
    } else {
      const createData: ICreateTemplateApi = {
        category: data.category ?? [],
        htmlData: data.htmlData ?? '',
        base64data: data.base64data ?? '',
        originalStylesKept: data.originalStylesKept ?? false,
        hyphens: data.hyphens ?? false,
        name: data.name ?? 'Unbenannte Vorlage',
        questions: data.questions ?? [],
      }
      res = await this.api().post('/v1/templates', createData)
    }
    return res.data.data
  }

  public addQuestion = async (props: any) => {
    const question = {
      ...props.question,
      locations: props.question?.locations?.map((l: any) => ({
        ...l,
        id: Number(l.id),
      })),
      options: props.question.options?.map((o: any) => ({
        ...o,
        locations: o.locations?.map((l: any) => ({
          ...l,
          id: Number(l.id),
        })),
      })),
    }
    const res = await this.api().post(`/v2/templates/${props.id}/questions`, question)
    return res.data.data
  }

  public get = async (data: IGetTemplatesApi): Promise<ITemplateExtended[]> => {
    const { type, lastItemFetched, limit, folderId } = data
    let url = `/list/${type}`
    if (folderId) {
      url = `/list/${type}/category`
    }
    const params = new URLSearchParams()
    if (limit) params.set('limit', limit.toString())
    if (lastItemFetched) params.set('startAfter', lastItemFetched)
    url = url + '?' + params.toString()
    try {
      const res = !folderId ? await this.api().get(`/v1/templates${url}`) : await this.api().post(`/v1/templates${url}`, { category: folderId })

      return res.data.data.templates.map((template: ITemplate) => ({
        ...template,
        rootFolder: type,
      }))
    } catch (err) {
      throw err
    }
  }

  public getRecents = async (): Promise<ITemplate[]> => {
    try {
      const res = await this.api().get('/v2/templates/list/recent')

      return res.data.data.templates
    } catch (err) {
      throw err
    }
  }

  public getOne = async (templateId: string, isPublic?: boolean, newDocxMicroservice?: boolean, documentId?: string): Promise<ITemplate> => {
    const version = newDocxMicroservice ? 'v2' : 'v1'
    const res = await this.api().get(
      `/${version}/templates/${isPublic ? 'public/' : ''}${templateId}${documentId ? `?documentId=${documentId}` : ''}`
    )
    const data = res.data.data
    let v3 = {}
    if (data.version === 'v3') v3 = v3parse(data.dataStructure, data.locations, data.cssData, data.questions, data.questionLayout)
    // if (data.signatureLevel) {
    //   const mapped = Object.entries(SECURITY_LEVEL_MAP).find(([_, value]) => value === data.signatureLevel)
    //   Object.assign(data, { signatureLevel: mapped ? mapped[0] : data.signatureLevel })
    // }
    Object.assign(v3, { title: data.name })
    // console.log('V3: ', v3)
    let beData = [] as any
    beData = parseBEData(data.dataStructure, data.cssData, data.questions)
    const [frontEndParsedStructure, frontEndEnrichedCSS] = beData
    if (data.version === 'v2') {
      Object.assign(data, {
        questions: [
          ...(data.questions?.map((q: any) => ({
            ...q,
            locations: [
              ...(q.locations?.map((l: any) => ({
                ...l,
                id: String(l.id),
              })) || []),
            ],
          })) || []),
        ],
      })
    }
    // const result = {
    //   ...data,
    //   questions: [
    //     ...(data.questions?.map((q: any) => ({
    //       ...q,
    //       locations: [
    //         ...(q.locations?.map((l: any) => ({
    //           ...l,
    //           id: String(l.id),
    //         })) || []),
    //       ],
    //     })) || []),
    //   ],
    // }
    if (data.dataStructure?.storageId) frontEndParsedStructure.storageId = data.dataStructure.storageId
    if (frontEndParsedStructure) data.dataStructure = frontEndParsedStructure
    // if (frontEndGeneratedHTML) result.htmlData = frontEndGeneratedHTML
    if (frontEndEnrichedCSS) data.cssData = frontEndEnrichedCSS
    data.v3 = v3

    // const temporary = { id1: 'Split 1 - Employer', id2: 'Split 2 - Employee', id3: 'Split 3 - Other' } // REMOVE AFTER DEVELOPMENT
    // Object.assign(data, { splits: temporary })

    Object.assign(data, { integrations: data.externalAPIs })
    delete data.externalAPIs

    return data
  }

  public generateAIQuestion = async (body: any): Promise<any> => {
    const res = await this.api().post(`/v2/templates/ai/question`, body)
    return res.data.data
  }

  public getSuggestedTemplates = async (text: string, threadId?: string): Promise<any> => {
    const res = await this.api().get(`/v2/templates/ai/suggest?text=${text}${threadId ? `&threadId=${threadId}` : ''}`)
    return res.data.data
  }

  public fetchHtml = async (templateId: string, data: any): Promise<any> => {
    // const res = await this.api().post(
    //   `/v2/templates/${templateId}/answered/preview`,
    //   { answers: [...data] }
    // )
    // return res.data.data
    return null
  }

  public update = async (props: IUpdateTemplateApi): Promise<ITemplate> => {
    // ================================================================================== //
    // ======================================= V3 ======================================= //
    // ================================================================================== //
    const isV3 = props.version === 'v3'
    if (isV3) {
      //@ts-ignore
      const { id, data, name } = props
      const filterKeys = [
        'dataStructure',
        'locations',
        'questions',
        'questionLayout',
        'languages',
        'approvers',
        'integrations',
        'splits',
        'signatureLevel',
        'dependencies',
      ]
      // console.log('PRE UNPARSE: ', data.dataStructure)
      const payload = parseV3forBE(
        Object.entries(data)
          .filter(([key]) => filterKeys.includes(key))
          .reduce((acc, [k, v]) => Object.assign(acc, { [k]: v }), {})
      ) as any
      // console.log(name, props)
      if (name) Object.assign(payload, { name })
      //@ts-ignore
      if (props?.data?.description) Object.assign(payload, { characteristics: props.data.description })
      // if (props?.data?.signatureLevel) Object.assign(payload, { signatureLevel: SECURITY_LEVEL_MAP[props.data.signatureLevel] })
      // console.log('POST UNPARSE: ', payload.dataStructure)

      //@ts-ignore
      if (props?.data?.integrations) {
        //@ts-ignore
        Object.assign(payload, { externalAPIs: props?.data?.integrations })
        delete payload.integrations
      }

      const res = await this.api().post(`/v2/templates/${id}`, payload)
      const result = res.data.data
      const v3 = v3parse(result.resultStructure, result.locations, result.cssData, result.questions, result.questionLayout)
      Object.assign(v3, { title: result.name })

      // console.log('RESULT: ', result)
      return result
    }
    // ================================================================================== //
    // ================================================================================== //
    // ================================================================================== //

    const version = props.new ? 'v2' : 'v1'
    let res = {} as any
    const payload = {
      status: props.data.status,
      name: props.data.name,
      originalStylesKept: props.data.originalStylesKept,
      settings: props.data.settings,
      characteristics: props.data.characteristics,
      sharingEnabled: props.data.sharingEnabled,
      category: props.data.category,
    } as any
    if (props.new) payload.questions = props.data.questions
    if (version === 'v2') {
      // res = await this.api().post(`/${version}/templates/${props.id}`, {
      res = await this.api().post(`/${version}/templates/${props.id}`, payload)
    } else {
      res = await this.api().post(`/${version}/templates/${props.id}`, {
        ...props.data,
      })
    }

    const data = res.data.data
    let beData = [] as any
    if (props.new) beData = parseBEData(data.dataStructure, data.cssData, data.questions)
    const [frontEndParsedStructure, frontEndEnrichedCSS] = beData
    const result = {
      ...data,
      questions: [
        ...(data.questions?.map((q: any) => ({
          ...q,
          locations: [
            ...(q.locations?.map((l: any) => ({
              ...l,
              id: String(l.id),
            })) || []),
          ],
        })) || []),
      ],
    }
    if (frontEndParsedStructure) result.dataStructure = frontEndParsedStructure
    // if (frontEndGeneratedHTML) result.htmlData = frontEndGeneratedHTML
    if (frontEndEnrichedCSS) result.cssData = frontEndEnrichedCSS

    return result
  }

  public uploadLogo = async (templateId: string, imageData: string): Promise<string> => {
    const res = await this.api().post(`/v1/templates/${templateId}/logo/upload`, {
      imageData,
    })
    return res.data.data.url
  }

  public getTemplateVersion = async (templateId: string, version: string, docXMicro: boolean, documentId?: string): Promise<ITemplate> => {
    const dxmVersion = docXMicro ? 'v2' : 'v1'
    const res = await this.api().get(`/${dxmVersion}/templates/${templateId}/version/${version}${documentId ? `?documentId=${documentId}` : ''}`)
    const data = res.data.data

    let v3 = {}
    if (data.version === 'v3') {
      v3 = v3parse(data.dataStructure, data.locations, data.cssData, data.questions, data.questionLayout)
    }
    Object.assign(v3, { title: data.name })

    let beData = [] as any
    if (docXMicro) beData = parseBEData(data.dataStructure, data.cssData, data.questions)
    const [frontEndParsedStructure, frontEndEnrichedCSS] = beData
    if (data.version === 'v2') {
      Object.assign(data, {
        questions: [
          ...(data.questions?.map((q: any) => ({
            ...q,
            locations: [
              ...(q.locations?.map((l: any) => ({
                ...l,
                id: String(l.id),
              })) || []),
            ],
          })) || []),
        ],
      })
    }
    if (data.dataStructure?.storageId) frontEndParsedStructure.storageId = data.dataStructure.storageId
    if (frontEndParsedStructure) data.dataStructure = frontEndParsedStructure
    // if (frontEndGeneratedHTML) data.htmlData = frontEndGeneratedHTML
    if (frontEndEnrichedCSS) data.cssData = frontEndEnrichedCSS
    data.v3 = v3
    return data
  }

  public duplicate = async ({ id }: IDuplicateTemplateApi, docXMicro: Boolean = false): Promise<void> => {
    const version = docXMicro ? 'v2' : 'v1'
    await this.api().post(`/${version}/templates/${id}/duplicate`)
  }

  public emptyTrashed = async () => {
    return await this.api().post('/v1/templates/empty/trashed')
  }

  public share = async (templateId: string, email: string): Promise<ITemplate> => {
    const res = await this.api().post(`/v1/templates/${templateId}/share`, {
      email,
      permissions: { read: true, write: false },
    })
    return res.data.data
  }

  public permissionsEdit = async (templateId: string, personId: string, permissions: IPermissions): Promise<ITemplate> => {
    const res = await this.api().patch(`/v1/templates/${templateId}/share/${personId}`, {
      permissions,
    })
    return res.data.data
  }

  public permissionsDelete = async (templateId: string, personId: string): Promise<ITemplate> => {
    const res = await this.api().delete(`/v1/templates/${templateId}/share/${personId}`)
    return res.data.data
  }

  // public upload = async (payload: IUploadTemplateApi): Promise<string> => {
  //   const asposeRes = await this.api().post('/v1/templates/', payload)
  //   const htmlData = asposeRes.data.data
  //   return htmlData
  // }
}

const templateApi = new TemplateApi()
export default templateApi
