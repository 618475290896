import React, { FunctionComponent, Dispatch, SetStateAction, RefCallback, useState, useMemo, useCallback, useEffect } from 'react'
import useStore, { ConnectMarkerToValueSourceAction } from '___store'

import { Caret, Connect } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { classes } from '../../..'

type UseStoreHookResultType = {
  connectMarkerToValueSource: ConnectMarkerToValueSourceAction
}

type Props = {
  id: string
  valueSources: string[]
  integrationId: string
  integrationLabel: string
  fields: { id: string; name: string; type: string }[]
}
export const Integration: FunctionComponent<Props> = React.memo(({ id, valueSources, integrationId, integrationLabel, fields }) => {
  const [accordion, setAccordion]: [HTMLDivElement | undefined, Dispatch<SetStateAction<HTMLDivElement | undefined>>] = useState()
  const [expanded, setExpanded] = useState(false)
  const accordionRef: RefCallback<HTMLDivElement | undefined> = useCallback(node => node && setAccordion(node), [])
  const { connectMarkerToValueSource } = useStore('connectMarkerToValueSource') as UseStoreHookResultType

  console.log('FIELDS: ', fields)

  const availableFields = useMemo(
    () =>
      (fields || []).reduce((result, { id: fieldId, name }) => {
        if (valueSources?.includes(`integration:${integrationId}:${fieldId}`)) return result
        result.push(
          <Button
            key={`Available-Value-Source-${integrationId}-${fieldId}`}
            className={classes.configEntry.markerConfiguration.fragment.connect.tabs.valueSources.integration}
            onClick={() => connectMarkerToValueSource({ id, valueSource: `external:${integrationId}:${fieldId}:0` })}
          >
            <Connect />
            <span>{name}</span>
          </Button>
        )
        return result
      }, [] as JSX.Element[]),
    [fields, valueSources, integrationId, connectMarkerToValueSource, id]
  )

  const style = useMemo(() => {
    if (!expanded) return
    return { height: `calc((3rem * ${availableFields.length + 1}) + 0.5rem + 2px)` }
  }, [expanded, availableFields.length])

  useEffect(() => {
    if (expanded && availableFields.length === 0) setExpanded(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFields.length])

  return (
    <div
      ref={accordionRef}
      className={classes.configEntry.markerConfiguration.fragment.connect.tabs.accordion}
      data-collapsed={expanded ? undefined : ''}
      style={style}
      data-type="external"
    >
      <Button
        className={classes.configEntry.markerConfiguration.fragment.connect.tabs.expandButton}
        onClick={() => {
          if (!expanded && accordion) accordion.scrollIntoView({ behavior: 'smooth' })
          setExpanded(prev => !prev)
        }}
        onClickBlur
        disabled={!availableFields.length}
      >
        {availableFields.length ? integrationLabel : `${integrationLabel}: No available fields...`}
        <Caret />
      </Button>
      {availableFields}
    </div>
  )
})

Integration.displayName = ''

export default Integration
