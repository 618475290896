import {
  WizardMode,
  WizardStateDataStructureNumbering,
  WizardStateDataStructureSections,
  DataStructure,
  Questions,
  QuestionLayout,
  Answers,
  Segments,
  Locations,
  WizardEditorStylesType,
  Integrations,
  Languages,
  SignatureSecurityLevel,
  Dependencies,
} from '___types'
import { WizardState } from './helpers'
import {
  selectWizardSectionByIndex,
  selectWizardSectionPageContentIdsByIndices,
  selectWizardSegmentsMarkerContentByIdAndParent,
  selectWizardSegmentById,
  selectWizardTableCellContentById,
  selectWizardSegmentsMarkerLocationById,
  selectWizardTextMarkerLocationById,
  selectWizardLocationByTypeAndId,
  selectWizardSegmentsMarkerLocationsByParentId,
  selectWizardTextMarkerLocationsByParentId,
  selectWizardQuestionById,
  selectWizardQuestionLayoutGroupByIndex,
  selectWizardAnswerById,
  selectWizardParagraphNumberingById,
  selectWizardLocationById,
  selectWizardDependenciesById,
  selectWizardDependentsById,
} from './selectorFunctions'

export type LocalStateType = { wizard: WizardState }

// ///////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////// //
// ================= SELECTOR RESULT TYPES ================= //
// ///////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////// //
export type WizardInitializedSelector = boolean
export type WizardModeSelector = WizardMode | null
export type WizardTemplateIdSelector = string | null
export type WizardTitleSelector = string | null
//
// ======================================================== //
// ======================== EDITOR ======================== //
// ======================================================== //
export type WizardEditorStylesSelector = WizardEditorStylesType | null
//
// ======================================================== //
// ==================== DATA STRUCTURE ==================== //
// ======================================================== //
export type WizardDataStructureSelector = DataStructure | null
export type WizardSectionsSelector = WizardStateDataStructureSections | undefined
export type WizardSectionCountSelector = number
export type WizardSegmentsSelector = Segments | undefined
export type WizardNumberingSelector = WizardStateDataStructureNumbering | undefined
//
// ========================================================= //
// ======================= LOCATIONS ======================= //
// ========================================================= //
export type WizardLocationsSelector = Locations | null
//
// ========================================================= //
// ======================= QUESTIONS ======================= //
// ========================================================= //
export type WizardShowExternalQuestionsSelector = boolean | null
export type WizardShowInternalQuestionsSelector = boolean | null
export type WizardQuestionsSelector = Questions | null
//
// ========================================================= //
// ==================== QUESTION LAYOUT ==================== //
// ========================================================= //
export type WizardQuestionLayoutSelector = QuestionLayout | null
export type WizardQuestionLayoutGroupCountSelector = number
export type WizardQuestionOrderSelector = string[] | null
//
// ======================================================== //
// ======================= LANGUAGE ======================= //
// ======================================================== //
export type WizardLanguagesSelector = Languages | null
export type WizardLanguageSelectSelector = 'single' | 'multi'
export type WizardAvailableLanguagesSelector = string
export type WizardSelectedLanguagesSelector = string
//
// ======================================================== //
// ======================= APPROVAL ======================= //
// ======================================================== //
export type WizardApproverListSelector = string[] | null
//
// ======================================================== //
// ===================== INTEGRATIONS ===================== //
// ======================================================== //
export type WizardIntegrationEntriesSelector = Record<string, string> | null
export type WizardIntegrationsSelector = Integrations | null
//
// ======================================================== //
// ======================== SPLITS ======================== //
// ======================================================== //
export type WizardSplitsSelector = Record<string, string> | null
export type WizardActiveSplitSelector = string | null
//
// ========================================================= //
// ======================= SIGNATURE ======================= //
// ========================================================= //
export type WizardSignatureSecurityLevelSelector = SignatureSecurityLevel | null
//
// ======================================================== //
// ====================== INSTANCING ====================== //
// ======================================================== //
export type WizardInstancingSelector = Record<string, number> | null
//
// ========================================================= //
// ======================== ANSWERS ======================== //
// ========================================================= //
export type WizardAnsweringSelector = string | null
export type WizardAnswersSelector = Answers | null
export type WizardAnswerRelevanceSelector = Record<string, boolean> | null
//
// ======================================================== //
// ===================== DEPENDENCIES ===================== //
// ======================================================== //
export type WizardDependenciesSelector = Dependencies | null
//
// ///////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////// //
//
//
//
const selectors = {
  // ///////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////// //
  // ======================= SELECTORS ======================= //
  // ///////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////// //
  selectWizardState: (state: LocalStateType): WizardState => state.wizard,
  selectWizardInitialized: (state: LocalStateType): WizardInitializedSelector => state.wizard.initialized,
  selectWizardMode: (state: LocalStateType): WizardModeSelector => state.wizard.mode,
  selectWizardTemplateId: (state: LocalStateType): WizardTemplateIdSelector => state.wizard.templateId,
  selectWizardTitle: (state: LocalStateType): WizardTitleSelector => state.wizard.title,
  selectWizardDescription: (state: LocalStateType): WizardTitleSelector => state.wizard.description,

  // ======================================================== //
  // ======================== EDITOR ======================== //
  // ======================================================== //
  selectWizardEditorStyles: (state: LocalStateType): WizardEditorStylesSelector => state.wizard.styles,
  // ======================================================== //

  // ======================================================== //
  // ==================== DATA STRUCTURE ==================== //
  // ======================================================== //
  selectWizardDataStructure: (state: LocalStateType): WizardDataStructureSelector => state.wizard.dataStructure,
  selectWizardSections: (state: LocalStateType): WizardSectionsSelector => state.wizard.dataStructure?.sections,
  selectWizardSectionCount: (state: LocalStateType): WizardSectionCountSelector => state.wizard.dataStructure?.sections.length || 0,
  selectWizardSectionByIndex,
  selectWizardSectionPageContentIdsByIndices,
  selectWizardSegmentsMarkerContentByIdAndParent,
  selectWizardSegments: (state: LocalStateType): WizardSegmentsSelector => state.wizard.dataStructure?.segments,
  selectWizardSegmentById,
  selectWizardTableCellContentById,
  selectWizardNumbering: (state: LocalStateType): WizardNumberingSelector => state.wizard.dataStructure?.numbering,
  selectWizardParagraphNumberingById,
  //
  // ========================================================= //
  // ======================= LOCATIONS ======================= //
  // ========================================================= //
  selectWizardLocations: (state: LocalStateType): WizardLocationsSelector => state.wizard.locations,
  selectWizardSegmentsMarkerLocationsByParentId,
  selectWizardTextMarkerLocationsByParentId,
  selectWizardSegmentsMarkerLocationById,
  selectWizardTextMarkerLocationById,
  selectWizardLocationByTypeAndId,
  selectWizardLocationById,
  //
  // ========================================================= //
  // ======================= QUESTIONS ======================= //
  // ========================================================= //
  selectWizardShowExternalQuestions: (state: LocalStateType): WizardShowExternalQuestionsSelector => state.wizard.showExternalQuestions,
  selectWizardShowInternalQuestions: (state: LocalStateType): WizardShowInternalQuestionsSelector => state.wizard.showInternalQuestions,
  selectWizardQuestions: (state: LocalStateType): WizardQuestionsSelector => state.wizard.questions,
  selectWizardQuestionById,
  //
  // ========================================================= //
  // ==================== QUESTION LAYOUT ==================== //
  // ========================================================= //
  selectWizardQuestionLayout: (state: LocalStateType): WizardQuestionLayoutSelector => state.wizard.questionLayout,
  selectWizardQuestionLayoutGroupCount: (state: LocalStateType): WizardQuestionLayoutGroupCountSelector => state.wizard.questionLayout?.length || 0,
  selectWizardQuestionLayoutGroupByIndex,
  selectWizardQuestionOrder: (state: LocalStateType): WizardQuestionOrderSelector => state.wizard.questionOrder,
  //
  // ======================================================== //
  // ======================= LANGUAGE ======================= //
  // ======================================================== //
  selectWizardLanguages: (state: LocalStateType): WizardLanguagesSelector => state.wizard.languages,
  selectWizardLanguageSelect: (state: LocalStateType): WizardLanguageSelectSelector => state.wizard.languages?.select || 'single',
  selectWizardAvailableLanguages: (state: LocalStateType): WizardAvailableLanguagesSelector => state.wizard.languages?.available?.join(';') || '',
  selectWizardSelectedLanguages: (state: LocalStateType): WizardSelectedLanguagesSelector => state.wizard.languages?.selected?.join(';') || '',
  //
  // ======================================================== //
  // ======================= APPROVAL ======================= //
  // ======================================================== //
  selectWizardApproverList: (state: LocalStateType): WizardApproverListSelector => state.wizard.approverList,
  //
  // ======================================================== //
  // ===================== INTEGRATIONS ===================== //
  // ======================================================== //
  selectWizardIntegrationEntries: (state: LocalStateType): WizardIntegrationEntriesSelector => state.wizard.integrationEntries,
  selectWizardIntegrations: (state: LocalStateType): WizardIntegrationsSelector => state.wizard.integrations,
  //
  // ======================================================== //
  // ======================== SPLITS ======================== //
  // ======================================================== //
  selectWizardSplits: (state: LocalStateType): WizardSplitsSelector => state.wizard.splits,
  selectWizardActiveSplit: (state: LocalStateType): WizardActiveSplitSelector => state.wizard.activeSplit,
  //
  // ========================================================= //
  // ======================= SIGNATURE ======================= //
  // ========================================================= //
  selectWizardSignatureSecurityLevel: (state: LocalStateType): WizardSignatureSecurityLevelSelector => state.wizard.signatureSecurityLevel,
  //
  // ======================================================== //
  // ====================== INSTANCING ====================== //
  // ======================================================== //
  selectWizardInstancing: (state: LocalStateType): WizardInstancingSelector => state.wizard.instancing,
  //
  // ========================================================= //
  // ======================== ANSWERS ======================== //
  // ========================================================= //
  selectWizardAnswering: (state: LocalStateType): WizardAnsweringSelector => state.wizard.answering,
  selectWizardAnswers: (state: LocalStateType): WizardAnswersSelector => state.wizard.answers,
  selectWizardAnswerById,
  selectWizardAnswerRelevance: (state: LocalStateType): WizardAnswerRelevanceSelector => state.wizard.answerRelevance,
  //
  // ======================================================== //
  // ===================== DEPENDENCIES ===================== //
  // ======================================================== //
  selectWizardDependencies: (state: LocalStateType): WizardDependenciesSelector => state.wizard.dependencies,
  selectWizardDependenciesById,
  selectWizardDependentsById,
  //
  // ///////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////// //
  // ///////////////////////////////////////////////////////// //
}

export { selectors }
export default selectors
