import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SECURITY_LEVELS, SignatureSecurityLevel } from '___types'
import { SecurityProps, securityClasses as classes } from '.'
import LevelButton from './Signature.Security.LevelButton'

export const Security = React.memo(
  forwardRef<SignatureSecurityLevel | null, SecurityProps>((props, ref) => {
    const { t: translate } = useTranslation('translation', { keyPrefix: 'components.signature' })
    const [stateLevel, setStateLevel] = useState<SignatureSecurityLevel | null>(null)

    const { level: propLevel, locked = false, onSelect } = props

    const computedLevel = useMemo(() => (propLevel === undefined ? stateLevel : propLevel), [propLevel, stateLevel])

    //@ts-ignore
    useImperativeHandle(ref, () => level)

    return (
      <div className={classes.wrapper} data-level={computedLevel}>
        {!computedLevel ? (
          <>
            <span className={classes.title}>{translate('signature-security')}</span>
            <span className={classes.description}>{translate('signature-security-guide')}</span>
            <div className={classes.entries}>
              <LevelButton
                level={SECURITY_LEVELS.BASE}
                onClick={() => {
                  setStateLevel(SECURITY_LEVELS.BASE)
                  if (typeof onSelect === 'function') onSelect(SECURITY_LEVELS.BASE)
                }}
              />
              <LevelButton
                level={SECURITY_LEVELS.HIGH}
                onClick={() => {
                  setStateLevel(SECURITY_LEVELS.HIGH)
                  if (typeof onSelect === 'function') onSelect(SECURITY_LEVELS.HIGH)
                }}
              />
              <LevelButton
                level={SECURITY_LEVELS.MAX}
                onClick={() => {
                  setStateLevel(SECURITY_LEVELS.MAX)
                  if (typeof onSelect === 'function') onSelect(SECURITY_LEVELS.MAX)
                }}
              />
            </div>
          </>
        ) : (
          <>
            <LevelButton
              level={computedLevel}
              onClick={() => {
                setStateLevel(null)
                if (typeof onSelect === 'function') onSelect(null)
              }}
              disabled={locked}
            />
            {!locked ? <span className={classes.configure}>Click to configure</span> : null}
          </>
        )}
      </div>
    )
  })
)

Security.displayName = 'Casus-Components-Signature-Security'

export default Security
