import React, { useCallback, useMemo } from 'react'
import useStore from '___store'

import { Button, ButtonGroup, Switch } from 'components'
import { ShowHideRule } from './components'
import { classes } from './index'

const visibilityOptions = ['hide', 'show']
const ruleLogicOptions = ['or', 'and']

const ShowHideFragment = React.memo(({ questionId }) => {
  const {
    questionRuleIds,
    questionVisibility = 'show',
    questionRuleLogic = 'or',
    updateQuestionVisibility,
    updateQuestionRuleLogic,
    addNewConditionalRule,
  } = useStore(
    `selectQuestionRuleIds[${questionId}]`,
    `selectQuestionVisibility[${questionId}]`,
    `selectQuestionRuleLogic[${questionId}]`,
    'updateQuestionVisibility',
    'updateQuestionRuleLogic',
    'addNewConditionalRule'
  )

  const ruleIds = useMemo(() => questionRuleIds?.split('; ').filter(o => o) || [], [questionRuleIds])
  const rules = useMemo(() => {
    return ruleIds.map(id => <ShowHideRule key={id} id={id} questionId={questionId} />)
  }, [ruleIds, questionId])

  const visibilityValue = Boolean(visibilityOptions.indexOf(questionVisibility))
  const logicValue = Boolean(ruleLogicOptions.indexOf(questionRuleLogic))

  const visibilityCallback = useCallback(
    value => {
      updateQuestionVisibility({ id: questionId, visibility: visibilityOptions[Number(value)] })
    },
    [updateQuestionVisibility, questionId]
  )
  const ruleLogicCallback = useCallback(
    value => updateQuestionRuleLogic({ id: questionId, logic: ruleLogicOptions[Number(value)] }),
    [updateQuestionRuleLogic, questionId]
  )

  return (
    <div className={classes.fragmentWrapper}>
      <span className={classes.fragmentTitle}>Show/Hide Conditional Logic</span>
      {rules.length ? (
        <div className={classes.showHideFragmentRulesDiv}>{rules}</div>
      ) : (
        <span className={classes.fragmentText}>
          Add rules (conditions) to determine whether this question should be shown or hidden ("Show/Hide" switch). When multiple rules are added,
          switching the logic ("Or/And " switch) will determine whether any or all conditions need to be met.
        </span>
      )}
      <ButtonGroup className={classes.showHideFragmentButtonGroup}>
        <Switch value={visibilityValue} onChange={visibilityCallback} labels={visibilityOptions} />
        {rules.length ? <Switch value={logicValue} onChange={ruleLogicCallback} labels={ruleLogicOptions} /> : null}
        <Button className={classes.showHideFragmentButton} onClick={() => addNewConditionalRule({ questionId })} colorScheme="primary">
          Add Rule
        </Button>
      </ButtonGroup>
    </div>
  )
})

ShowHideFragment.displayName = 'Wizard-Configuration-AdvancedQuestionConfiguration-ShowHideFragment'

export default ShowHideFragment
