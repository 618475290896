import { useMemo } from 'react'
import useStore, {
  WizardDataStructureSelector,
  WizardSectionByIndexSelector,
  WizardSegmentsMarkerContentByIdAndParentSelector,
  WizardLocationByTypeAndIdSelector,
  WizardState,
  getMappedSegmentIds,
  getMarkerByTypeAndId,
  WizardQuestionsSelector,
  WizardQuestionLayoutSelector,
} from '___store'

import { FULL_RANGE, LOCATION_TYPES, MarkerKeep, MarkerReplace, SEGMENTS_MARKER_ID_MATCH, SegmentsLocation } from '___types'
import { useEditorContext } from '..'

type UseStoreHookContentResultType = { wizardSegmentsMarkerContentByIdAndParent: WizardSegmentsMarkerContentByIdAndParentSelector }
type UseStoreHookLocationResultType = {
  wizardLocationByTypeAndId: WizardLocationByTypeAndIdSelector
  wizardQuestions: WizardQuestionsSelector
  wizardQuestionLayout: WizardQuestionLayoutSelector
}
type UseTextMarkerResultType = {
  start: boolean
  end: boolean
  color: string
  contentCustomStyle: string
  contentStyles: string[]
  contentIds: string[]
  defaultKeep: boolean
  optionIds: string[]
  keep?: MarkerKeep
  replace?: MarkerReplace
  instancing?: string
  instanceCount: number
  questionParents: Record<string, string>
  optionParents: Record<string, string>
  valueSources: string[]
  combine: boolean
  calculation?: string
  formatting?: string
}

export const useSegmentsMarker = (id: string, parent: string): UseTextMarkerResultType => {
  const editorContext = useEditorContext()
  const { dataStructure } = editorContext
  const { sections } = dataStructure || ({} as WizardDataStructureSelector)!

  let pageStart = FULL_RANGE[0] as number
  let pageEnd = FULL_RANGE[1]
  const staticPseudoState = Object.assign({}, editorContext as unknown) as WizardState
  const {
    range,
    contentCustomStyle = '',
    contentStyles = [],
    valueSources,
    combine = false,
    calculation,
  } = (getMarkerByTypeAndId(staticPseudoState, LOCATION_TYPES.SEGMENTS, id)[0] || {}) as SegmentsLocation
  const contextLocationRange = range || FULL_RANGE
  const { isHeaderFooter, parentId, sectionIndex, pageIndex } = parent.match(SEGMENTS_MARKER_ID_MATCH)?.groups || {}
  // const { isHeaderFooter, headerFooterId, parentId, sectionIndex, pageIndex } = parent.match(SEGMENTS_MARKER_ID_MATCH)?.groups || {}
  if (!isHeaderFooter && sectionIndex && pageIndex) {
    const contextSection = sections && sectionIndex && sections[Number(sectionIndex)]
    const { pages } = contextSection || ({} as WizardSectionByIndexSelector)!
    const contextPageRange = (pages && pageIndex && pages[Number(pageIndex)]) || FULL_RANGE
    pageStart = Number(contextPageRange[0])
    pageEnd = Number(contextPageRange[1])
  }
  const relevantRange = [Math.max(contextLocationRange[0], pageStart), Math.min(contextLocationRange[1], pageEnd)]
  const headerFooterSegments = undefined as unknown as string[] // Implement later
  //   const headerFooterSegments = isHeaderFooter ? (selectors.selectHeaderFooterById(state, parentId)?.segments || []).map(({ id }) => id) : undefined

  const mappedSegmentIds = getMappedSegmentIds(staticPseudoState, parentId, headerFooterSegments).slice(...relevantRange)

  const topLayerContentIds = mappedSegmentIds.map(segmentId => segmentId.split(`;marker:${id}`)[0].split(';').pop())
  const contextSegmentsMarkerContentIds = `${contextLocationRange[0] >= pageStart ? ';' : ''}${Array.from(new Set(topLayerContentIds)).join(';')}${
    contextLocationRange[1] <= pageEnd ? ';' : ''
  }`

  const { wizardSegmentsMarkerContentByIdAndParent = '' } = useStore(
    !contextSegmentsMarkerContentIds ? `selectWizardSegmentsMarkerContentByIdAndParent[${id},${parent}]` : undefined
  ) as UseStoreHookContentResultType

  const { wizardLocationByTypeAndId, wizardQuestions, wizardQuestionLayout } = useStore(
    `selectWizardLocationByTypeAndId[${LOCATION_TYPES.SEGMENTS},${id}]`,
    'selectWizardQuestions',
    'selectWizardQuestionLayout'
  ) as UseStoreHookLocationResultType
  const {
    color,
    defaultKeep = true,
    optionIds = [],
    keep,
    replace,
    instancing,
    instanceCount = 1,
    formatting,
  } = wizardLocationByTypeAndId || ({} as WizardLocationByTypeAndIdSelector)!

  const questionParents = useMemo(
    () =>
      (wizardQuestionLayout || []).reduce(
        (result, layoutGroup) =>
          layoutGroup.questions.reduce((result, questionId) => Object.assign(result, { [questionId]: layoutGroup.id }), result),
        {} as Record<string, string>
      ),
    [wizardQuestionLayout]
  )

  const optionParents = useMemo(
    () =>
      optionIds.reduce((result, optionId) => {
        const id = optionId.split(':')[0]
        const question = wizardQuestions?.find(({ optionGroups }) =>
          optionGroups.some(({ options }) => options.some(({ id: optionId }) => optionId === id))
        )
        return Object.assign(result, question && { [id]: question.id })
      }, {} as Record<string, string>),
    [optionIds, wizardQuestions]
  )

  const resultingTopLayerContentIds = contextSegmentsMarkerContentIds || wizardSegmentsMarkerContentByIdAndParent
  const [start, contentIds, end] = useMemo(() => {
    const contentIdsSplit = resultingTopLayerContentIds.split(';')
    const splitCount = contentIdsSplit.length
    const [start, end] = [contentIdsSplit[0] === '', contentIdsSplit[splitCount - 1] === '']
    const result = contentIdsSplit.slice(Number(start), splitCount - Number(end)).filter(id => id)
    return [start, result, end]
  }, [resultingTopLayerContentIds])

  const useSegmentsMarkerReturn = {
    start,
    end,
    color,
    contentCustomStyle,
    contentStyles,
    contentIds,
    defaultKeep,
    optionIds,
    keep,
    replace,
    instancing,
    instanceCount,
    questionParents,
    optionParents,
    valueSources,
    combine,
    calculation,
    formatting,
  }

  return useSegmentsMarkerReturn
}

export default useSegmentsMarker
