import React, { FunctionComponent, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { ANSWERING_IDS_MATCH, DOCUMENT_DIRECTORY } from '___types'
import Question from './Question'
import {
  WizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupProps,
  wizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupClasses as classes,
  useWizardLayoutContext,
} from 'Layouts/WizardLayout'
import NewInstancePrompt from './NewInstancePrompt'
// import useStore from '___store'
// import { QuestionGroupContextProvider } from '.'

const QuestionGroup: FunctionComponent<WizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupProps> = React.memo(({ id }) => {
  const { templateId, documentId } = useParams() as { templateId: string; documentId: string }
  // const [instanceIndex, setInstanceIndex] = useState(0)
  // const [instancePromptStep, setInstancePromptStep] = useState(false)

  const publicFlow = useWizardLayoutContext()

  const { instanceIndex, questionId, orderString, isFirst, isLast } = useMemo(() => id?.match(ANSWERING_IDS_MATCH)?.groups || {}, [id])

  // const { questionLayoutGroupById } = useStore(`selectQuestionLayoutGroupById[${questionLayoutGroupId}]`)

  // const { questions, insantiable } = questionLayoutGroupById as { questions: string[]; insantiable?: boolean }
  // const firstInGroup = questions.indexOf(questionId) === 0
  // const lastInGroup = questions.indexOf(questionId) === questions.length - 1

  // const handleFirstPrevious = useCallback(() => {}, [])
  // const handleLastNext = useCallback(() => {}, [])

  const question = useMemo(
    () =>
      questionId === 'new-instance-prompt' ? (
        <NewInstancePrompt id={id!} />
      ) : (
        <Question
          key={questionId}
          instanceIndex={Number(instanceIndex ?? 0)}
          id={questionId}
          orderString={orderString}
          isFirst={Boolean(isFirst)}
          isLast={Boolean(isLast)}
          // firstInGroup={firstInGroup}
          // lastInGroup={lastInGroup}
        />
      ),
    [questionId, instanceIndex, id, orderString, isFirst, isLast]
  )

  if (id === 'redirect-backward') return <Redirect to={`/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${templateId}/${documentId}?step=configure`} />
  if (id === 'redirect-forward') return <Redirect to={`/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${templateId}/${documentId}?step=review`} />

  // console.log('GRP: ', questionLayoutGroupById)

  return (
    <div className={classes.wrapper}>
      {/* <QuestionGroupContextProvider instanceIndex={instanceIndex}> */}
      {question}
      {/* </QuestionGroupContextProvider> */}
    </div>
  )
})

QuestionGroup.displayName = 'WizardLayout-LeftPane-Document-Questionnaire-QuestionGroup'

export default QuestionGroup
