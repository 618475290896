import React, { useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import PropTypes from 'prop-types'

import useStore from '___store'
import { answersType, dataStructureType, locationsType, numberingSystemType, questionsType } from 'utilities/propTypes'

import { Header, Editor, Configuration } from './components'
import Popup from './components/Popup'

export const classes = {
  wrapper: 'Wizard-wrapper',
  content: 'Wizard-content',
}

export const Wizard = React.memo(props => {
  const { resetWizard, initializeWizard } = useStore('resetWizard', 'initializeWizard')

  const {
    id,
    title,
    description,
    dataStructure,
    locations,
    cssData: styles,
    numberingSystem,
    questions,
    questionLayout,
    languages,
    approverList,
    integrations,
    externals,
    splits,
    signatureSecurityLevel,
    answers,
    mode,
    dependencies,
    closeHandler,
    saveHandler,
  } = props

  const parseParagraphs = (segments = []) =>
    segments
      .reduce((accumulated, current) => {
        const { id, type, textChunks = [] } = current
        if (type === 'table')
          return accumulated.concat(
            ['header', 'footer', 'body'].reduce(
              (combinedSections, section) =>
                (current[section] || []).reduce(
                  (combinedRows, { cells = [] }) =>
                    cells.reduce((combinedCells, { content }) => combinedCells.concat(parseParagraphs(content)), combinedRows),
                  combinedSections
                ),
              []
            )
          )
        if (type === 'paragraph') return accumulated.concat({ id, text: textChunks.map(({ text }) => text).join('') })
        return accumulated
      }, [])
      .filter(({ text }) => text.length)
  const parsedParagraphs = parseParagraphs(dataStructure?.segments || [])

  const rgxp = /(?<placeholder>\[(?<contentText>[A-Z0-9_\s]*)\])/g

  const parsedMarkers = parsedParagraphs.reduce((result, { id, text }) => {
    const resultingMarkers = Array.from(text.matchAll(rgxp) || []).map(match => ({
      id: uuid(),
      type: 'text',
      defaultKeep: true,
      contentText: match.groups.contentText,
      range: [match.index, match.index + match.groups.placeholder.length],
      optionIds: [],
      questionId: null,
    }))
    if (resultingMarkers.length) Object.assign(result, { [id]: resultingMarkers })
    return result
  }, {})

  const existingLocations = Object.assign({}, locations) || { segments: {}, text: {} }
  const { text: textLocations } = existingLocations
  const parsedTextLocations = Object.entries(parsedMarkers).reduce(
    (result, [id, markerArray]) => Object.assign(result, { [id]: (result[id] || []).concat(markerArray) }),
    Object.assign({}, textLocations)
  )
  const locationsExist = Object.values(Object.assign({}, locations.segments, locations.text)).length
  const allLocations = !locationsExist ? Object.assign(existingLocations, { text: parsedTextLocations }) : existingLocations

  useEffect(() => {
    const configuring = [{ key: mode, id: mode }]
    const firstLayoutGroup = questionLayout && questionLayout.find(({ type }) => !['sub-questions', 'separator'].includes(type))
    const answering =
      (mode === 'document-generation' && (firstLayoutGroup?.type === 'loose' ? firstLayoutGroup?.questions[0] : firstLayoutGroup?.id)) || null
    const validExternals = externals?.filter(({ fields }) => fields.length) || []
    const initialSettings = {
      title: title || `New ${mode === 'document-generation' ? 'Document' : 'Template'}`,
      description: description || '',
      configuring: configuring || [],
      answering: answering || null,
      dataStructure: dataStructure,
      locations: allLocations,
      styles: styles || {},
      numberingSystem: numberingSystem || {},
      questions: questions || [],
      questionLayout: questionLayout || [],
      languages: languages || { available: [], select: 'single' },
      approverList: approverList || [],
      integrations: integrations || {},
      externals: validExternals || [],
      splits: splits || {},
      signatureSecurityLevel: signatureSecurityLevel || null,
      answers: answers || [],
      mode: mode,
      dependencies: dependencies || null,
    }
    initializeWizard(initialSettings)
    return resetWizard
  }, [
    mode,
    title,
    description,
    dataStructure,
    allLocations,
    styles,
    numberingSystem,
    questions,
    questionLayout,
    languages,
    approverList,
    integrations,
    externals,
    splits,
    signatureSecurityLevel,
    answers,
    dependencies,
    initializeWizard,
    resetWizard,
  ])

  return (
    <main className={classes.wrapper}>
      <Header id={id} onCloseHandler={closeHandler} onSaveHandler={saveHandler} />
      <section className={classes.content}>
        <Configuration />
        <Editor />
      </section>
      {/* <Footer /> */}
      <Popup />
    </main>
  )
})

Wizard.displayName = 'Wizard'

Wizard.propTypes = {
  dataStructure: dataStructureType(),
  locations: locationsType(),
  // cssData: cssDataType(),
  numberingSystem: numberingSystemType(),
  questions: questionsType(),
  answers: answersType(),
  mode: PropTypes.oneOf(['template-creation', 'document-generation']).isRequired,
}

export default Wizard
