import React, { FunctionComponent, useMemo } from 'react'
import useStore, { AddQuestionConditionGroupAction, ToggleQuestionConditionalLogicAction, WizardQuestionByIdSelector } from '___store'

import { LOGIC, QuestionConditionGroup } from '___types'
import { Button, Switch } from 'components/CasusComponents'
import { classes } from '.'
import QuestionConditionGroupComponent from './QuestionConditionGroup'

type UseStoreHookResultType = {
  wizardQuestionById: WizardQuestionByIdSelector
  toggleQuestionConditionalLogic: ToggleQuestionConditionalLogicAction
  addQuestionConditionGroup: AddQuestionConditionGroupAction
  concludeConfiguring: any
}
export const QuestionConditionalConfiguration: FunctionComponent<{ key: string; id: string }> = React.memo(({ id }) => {
  const { wizardQuestionById, toggleQuestionConditionalLogic, addQuestionConditionGroup, concludeConfiguring } = useStore(
    `selectWizardQuestionById[${id}]`,
    'toggleQuestionConditionalLogic',
    'addQuestionConditionGroup',
    'concludeConfiguring'
  ) as UseStoreHookResultType

  const { conditions: { logic = 'and' } = {} } = wizardQuestionById || {}

  const conditionGroups = useMemo(() => {
    const groups = Object.assign({}, wizardQuestionById?.conditions)
    delete groups.logic
    return groups as Record<string, QuestionConditionGroup>
  }, [wizardQuestionById])

  const groupRender = useMemo(
    () =>
      Object.entries(conditionGroups).map(([groupId, group]) => {
        const conditions = Object.assign({}, group)
        delete conditions.logic
        return (
          <QuestionConditionGroupComponent
            key={`Question-${id}-ConditionalConfiguration-ConditionGroup-${groupId}`}
            questionId={id}
            id={groupId}
            group={group}
          />
        )
      }),
    [conditionGroups, id]
  )

  return (
    <div className={classes.wrapper}>
      <div className={classes.actions}>
        <Switch value={logic === LOGIC.OR} onClick={() => toggleQuestionConditionalLogic(id)} pin={logic} />
        <Button onClick={() => addQuestionConditionGroup(id)}>Add condition qroup</Button>
      </div>
      {groupRender}
      <Button onClick={() => concludeConfiguring()} primary>
        Conclude
      </Button>
    </div>
  )
})

QuestionConditionalConfiguration.displayName = 'Question-ConditionalConfiguration'

export default QuestionConditionalConfiguration
