import {
  EditorMode,
  ExternalServiceField,
  LocationType,
  Option,
  OptionGroup,
  OptionGroupSelectUnionType,
  TableCells,
  TableRows,
  TableSectionsType,
  TextChunks,
} from '___types'

import { WizardLayout } from './WizardLayout'
import { Header, LeftPane, RightPane, Footer } from './components'

import './style.scss'
import React, { PropsWithChildren, useContext } from 'react'
import { FunctionComponent } from 'enzyme'

export type WizardLayoutRightPaneEditorInteractFocusModeProps = {}
export type WizardLayoutRightPaneEditorInteractFocusModeOverlayProps = { focusedMarkersIds: string }

// /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ================================================= CLASSES ================================================= //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////// //
export const wizardLayoutFooterClasses = { wrapper: 'WizardLayout-Footer-wrapper' } as const
export const wizardLayoutRightPaneEditorInteractFocusModeClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Interact-FocusMode-wrapper',
  switch: 'WizardLayout-RightPane-Editor-Interact-FocusMode-Switch-wrapper',
  overlay: {
    wrapper: 'WizardLayout-RightPane-Editor-Interact-FocusMode-Overlay-wrapper',
    svgMask: 'WizardLayout-RightPane-Editor-Interact-FocusMode-Overlay-svgMask',
    clipPath: 'WizardLayout-RightPane-Editor-Interact-FocusMode-Overlay-clipPath',
  },
}
export const wizardLayoutLeftPaneDocumentPreviewUploadToBambooClasses = {
  wrapper: 'WizardLayout-LeftPane-DocumentPreview-UploadToBambooSection-wrapper',
  title: 'WizardLayout-LeftPane-DocumentPreview-UploadToBambooSection-title',
  select: {
    wrapper: 'WizardLayout-LeftPane-DocumentPreview-UploadToBambooSection-select-wrapper',
    dropdown: 'WizardLayout-LeftPane-DocumentPreview-UploadToBambooSection-select-dropdown',
    switch: 'WizardLayout-LeftPane-DocumentPreview-UploadToBambooSection-select-switch',
    button: 'WizardLayout-LeftPane-DocumentPreview-UploadToBambooSection-select-button',
  },
} as const
export const wizardLayoutLeftPaneQuestionnaireDocumentRenameClasses = {
  wrapper: 'WizardLayout-LeftPane-Questionnaire-DocumentRename-wrapper',
  input: 'WizardLayout-LeftPane-Questionnaire-DocumentRename-input',
  aiGeneratedName: 'WizardLayout-LeftPane-Questionnaire-DocumentRename-aiGeneratedName',
  texts: { primary: 'WizardLayout-LeftPane-Questionnaire-DocumentRename-texts-primary' },
  actions: { wrapper: 'WizardLayout-LeftPane-Questionnaire-DocumentRename-actions-wrapper' },
} as const
// ========================================================================================================== //
// ========================================================================================================== //
// =============================================== PROPERTIES =============================================== //
// ========================================================================================================== //
// ========================================================================================================== //
//
export type WizardLayoutProps = {}
//
// ========================================================================================================== //
// ================================================= HEADER ================================================= //
// ========================================================================================================== //
export type WizardLayoutHeaderProps = {}
export type WizardLayoutHeaderDynamicButtonProps = {}
// ========================================================================================================== //
//
// ========================================================================================================== //
// =============================================== LEFT PANE ================================================ //
// ========================================================================================================== //
export type WizardLayoutLeftPaneProps = {}
export type WizardLayoutLeftPaneDocumentTemplateSelectionProps = {}
export type WizardLayoutLeftPaneDocumentConfigurationProps = {}
export type WizardLayoutLeftPaneDocumentConfigurationPaywallProps = {}
export type WizardLayoutLeftPaneDocumentConfigurationLanguagesProps = {}
export type WizardLayoutLeftPaneDocumentConfigurationIntegrationsProps = { loadingCallback: (id: string, isLoading: boolean) => void }
export type WizardLayoutLeftPaneDocumentConfigurationIntegrationsInstanceProps = {
  id: string
  integrationId: string
  title: string
  fields: ExternalServiceField[]
  loadingCallback: (id: string, isLoading: boolean) => void
}
export type WizardLayoutLeftPaneDocumentConfigurationActionsProps = { isLoading: boolean }
export type WizardLayoutLeftPaneDocumentQuestionnaireProps = {}
export type WizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupProps = { id: string | null }
export type WizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupNewInstancePromptProps = { id: string }
export type WizardLayoutLeftPaneDocumentQuestionnaireQuestionProps = {
  instanceIndex: number
  id: string
  orderString: string
  isFirst: boolean
  isLast: boolean
  // firstInGroup: boolean
  // lastInGroup: boolean
}
export type WizardLayoutLeftPaneDocumentQuestionnaireOptionGroupProps = {
  instanceIndex: number
  optionGroup: OptionGroup
  singleGroup: boolean
  indexOffset: number
  firstGroup: boolean
}
export type WizardLayoutLeftPaneDocumentQuestionnaireOptionProps = {
  instanceIndex: number
  option: Option
  index: number
  autoConfirm: boolean
  singleOption: boolean
  select: OptionGroupSelectUnionType
  firstOption: boolean
}
export type WizardLayoutLeftPaneDocumentQuestionnaireQuestionOverviewProps = {}
export type WizardLayoutLeftPaneDocumentQuestionnaireQuestionOverviewListQuestionProps = { id: string; collapseCallback: () => void }
export type WizardLayoutLeftPaneDocumentReviewProps = {}
export type WizardLayoutLeftPaneDocumentReviewSkippedQuestionsProps = { questionCount: number }
export type WizardLayoutLeftPaneDocumentReviewConfirmProps = {}
export type WizardLayoutLeftPaneDocumentReviewRenameProps = {}
export type WizardLayoutLeftPaneDocumentPreviewProps = {}

// ========================================================================================================== //
//
// ========================================================================================================== //
// =============================================== RIGHT PANE =============================================== //
// ========================================================================================================== //
export type WizardLayoutRightPaneProps = {}
export type WizardLayoutRightPaneEditorProps = { mode?: EditorMode }
export type WizardLayoutRightPaneEditorInteractProps = {}
export type WizardLayoutRightPaneEditorPreviewProps = {}
export type WizardLayoutRightPaneEditorSectionProps = { index: number }
export type WizardLayoutRightPaneEditorPageProps = { index: number }
export type WizardLayoutRightPaneEditorSegmentProps = { id: string }
export type WizardLayoutRightPaneEditorSegmentParagraphProps = { id: string; customStyle: string; styles: string[]; textChunks?: TextChunks }
export type WizardLayoutRightPaneEditorSegmentTableProps = { id: string; customStyle: string; styles: string[]; tableSections?: TableSectionsType }
export type WizardLayoutRightPaneEditorSegmentTableHeaderProps = { rows: TableRows; index: number }
export type WizardLayoutRightPaneEditorSegmentTableBodyProps = { rows: TableRows; index: number }
export type WizardLayoutRightPaneEditorSegmentTableFooterProps = { rows: TableRows; index: number }
export type WizardLayoutRightPaneEditorSegmentTableRowProps = { cells: TableCells }
export type WizardLayoutRightPaneEditorSegmentTableCellProps = { id: string }
export type WizardLayoutRightPaneEditorSegmentImageProps = {}
export type WizardLayoutRightPaneEditorMarkerProps = { id: string; type: LocationType; parent?: string; textChunks?: TextChunks }
export type WizardLayoutRightPaneEditorMarkerSegmentsProps = { id: string; parent: string }
export type WizardLayoutRightPaneEditorMarkerSegmentsParagraphProps = { id: string; customStyle: string; styles: string[]; text: string }
export type WizardLayoutRightPaneEditorMarkerTextProps = { id: string; range: [number, number]; textChunks: TextChunks }
// ========================================================================================================== //
//
// ========================================================================================================== //
// ================================================= FOOTER ================================================= //
// ========================================================================================================== //
export type WizardLayoutFooterProps = {}
// ========================================================================================================== //
//
//
//
//
//
// ========================================================================================================== //
// ========================================================================================================== //
// =============================================== CLASSNAMES =============================================== //
// ========================================================================================================== //
// ========================================================================================================== //
//
//
//
// ========================================================================================================== //
// =============================================== RIGHT PANE =============================================== //
// ========================================================================================================== //
export const wizardLayoutRightPaneEditorMarkerTextClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Marker-Text-wrapper',
  instance: 'WizardLayout-RightPane-Editor-Marker-Text-instance',
} as const
export const wizardLayoutRightPaneEditorMarkerSegmentsParagraphClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Marker-Segments-Paragraph-wrapper',
} as const
export const wizardLayoutRightPaneEditorMarkerSegmentsClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Marker-Segments-wrapper',
  instance: 'WizardLayout-RightPane-Editor-Marker-Segments-instance',
  paragraph: wizardLayoutRightPaneEditorMarkerSegmentsParagraphClasses,
} as const
export const wizardLayoutRightPaneEditorMarkerClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Marker-wrapper',
  segments: wizardLayoutRightPaneEditorMarkerSegmentsClasses,
  text: wizardLayoutRightPaneEditorMarkerTextClasses,
} as const
export const wizardLayoutRightPaneEditorSegmentImageClasses = { wrapper: 'WizardLayout-RightPane-Editor-Segment-Image-wrapper' } as const
export const wizardLayoutRightPaneEditorSegmentTableCellClasses = { wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-Cell-wrapper' } as const
export const wizardLayoutRightPaneEditorSegmentTableRowClasses = { wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-Row-wrapper' } as const
export const wizardLayoutRightPaneEditorSegmentTableSectionFooterClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-TableSection-Footer-wrapper',
} as const
export const wizardLayoutRightPaneEditorSegmentTableSectionBodyClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-TableSection-Body-wrapper',
} as const
export const wizardLayoutRightPaneEditorSegmentTableSectionHeaderClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-TableSection-Header-wrapper',
} as const
export const wizardLayoutRightPaneEditorSegmentTableSectionClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-wrapper',
  header: wizardLayoutRightPaneEditorSegmentTableSectionHeaderClasses,
  body: wizardLayoutRightPaneEditorSegmentTableSectionBodyClasses,
  footer: wizardLayoutRightPaneEditorSegmentTableSectionFooterClasses,
  row: wizardLayoutRightPaneEditorSegmentTableRowClasses,
  cell: wizardLayoutRightPaneEditorSegmentTableCellClasses,
} as const
export const wizardLayoutRightPaneEditorSegmentTableClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Table-wrapper',
  tableSection: wizardLayoutRightPaneEditorSegmentTableSectionClasses,
} as const
export const wizardLayoutRightPaneEditorSegmentParagraphClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-Paragraph-wrapper',
  chunk: 'WizardLayout-RightPane-Editor-Segment-Paragraph-chunk',
} as const
export const wizardLayoutRightPaneEditorSegmentClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Segment-wrapper',
  paragraph: wizardLayoutRightPaneEditorSegmentParagraphClasses,
  table: wizardLayoutRightPaneEditorSegmentTableClasses,
  image: wizardLayoutRightPaneEditorSegmentImageClasses,
  marker: wizardLayoutRightPaneEditorMarkerClasses,
} as const
export const wizardLayoutRightPaneEditorPageClasses = { wrapper: 'WizardLayout-RightPane-Editor-Page-wrapper' } as const
export const wizardLayoutRightPaneEditorSectionClasses = { wrapper: 'WizardLayout-RightPane-Editor-Section-wrapper' } as const
export const wizardLayoutRightPaneEditorPreviewClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Preview-wrapper',
  content: 'WizardLayout-RightPane-Editor-Preview-content',
  section: wizardLayoutRightPaneEditorSectionClasses,
  page: wizardLayoutRightPaneEditorPageClasses,
  segment: wizardLayoutRightPaneEditorSegmentClasses,
} as const
export const wizardLayoutRightPaneEditorInteractClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-Interact-wrapper',
  content: 'WizardLayout-RightPane-Editor-Interact-content',
  section: wizardLayoutRightPaneEditorSectionClasses,
  page: wizardLayoutRightPaneEditorPageClasses,
  segment: wizardLayoutRightPaneEditorSegmentClasses,
  focusModeToggle: wizardLayoutRightPaneEditorInteractFocusModeClasses,
} as const
export const wizardLayoutRightPaneEditorClasses = {
  wrapper: 'WizardLayout-RightPane-Editor-wrapper',
  interact: wizardLayoutRightPaneEditorInteractClasses,
  preview: wizardLayoutRightPaneEditorPreviewClasses,
} as const
export const wizardLayoutRightPaneClasses = { wrapper: 'WizardLayout-RightPane-wrapper', editor: wizardLayoutRightPaneEditorClasses } as const
// ========================================================================================================== //
//
// ========================================================================================================== //
// ================================================ LEFT PANE =============================================== //
// ========================================================================================================== //

export const wizardLayoutLeftPaneDocumentPreviewClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Preview-wrapper',
  message: {
    primary: 'WizardLayout-LeftPane-Document-Preview-message-primary',
    secondary: 'WizardLayout-LeftPane-Document-Preview-message-secondary',
  },
  actions: {
    wrapper: 'WizardLayout-LeftPane-Document-Preview-actions-wrapper',
    splits: {
      wrapper: 'WizardLayout-LeftPane-Document-Preview-actions-splits-wrapper',
      entry: 'WizardLayout-LeftPane-Document-Preview-actions-splits-entry',
    },
  },
  editWarning: 'WizardLayout-LeftPane-Document-Preview-editWarning',
} as const
export const wizardLayoutLeftPaneDocumentReviewRenameClasses = { wrapper: 'WizardLayout-LeftPane-Document-Review-Rename-wrapper' } as const
export const wizardLayoutLeftPaneDocumentReviewConfirmClasses = { wrapper: 'WizardLayout-LeftPane-Document-Review-Confirm-wrapper' } as const
export const wizardLayoutLeftPaneDocumentReviewSkippedQuestionsClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Review-SkippedQuestions-wrapper',
} as const
export const wizardLayoutLeftPaneDocumentReviewClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Review-wrapper',
  message: {
    wrapper: 'WizardLayout-LeftPane-Document-Review-message-wrapper',
    primary: 'WizardLayout-LeftPane-Document-Review-message-primary',
    secondary: 'WizardLayout-LeftPane-Document-Review-message-secondary',
  },
  renameInput: 'WizardLayout-LeftPane-Document-Review-renameInput',
  actions: 'WizardLayout-LeftPane-Document-Review-actions',
  skippedQuestions: wizardLayoutLeftPaneDocumentReviewSkippedQuestionsClasses,
  confirm: wizardLayoutLeftPaneDocumentReviewConfirmClasses,
  rename: wizardLayoutLeftPaneDocumentReviewRenameClasses,
}
export const wizardLayoutLeftPaneDocumentQuestionnaireQuestionOverviewClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-QuestionOverview-wrapper',
  expandButton: 'WizardLayout-LeftPane-Document-Questionnaire-QuestionOverview-expandButton',
  questionListDiv: 'WizardLayout-LeftPane-Document-Questionnaire-QuestionOverview-questionListDiv',
} as const
export const wizardLayoutLeftPaneDocumentQuestionnaireOptionClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-Option-wrapper',
  optionLabel: 'WizardLayout-LeftPane-Document-Questionnaire-Option-optionLabel',
} as const
export const wizardLayoutLeftPaneDocumentQuestionnaireOptionGroupClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-OptionGroup-wrapper',
} as const
export const wizardLayoutLeftPaneDocumentQuestionnaireQuestionClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-Question-wrapper',
  orderNumber: 'WizardLayout-LeftPane-Document-Questionnaire-Question-orderNumber',
  details: {
    wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-Question-details-wrapper',
    text: 'WizardLayout-LeftPane-Document-Questionnaire-Question-details-text',
    description: 'WizardLayout-LeftPane-Document-Questionnaire-Question-details-description',
    hint: 'WizardLayout-LeftPane-Document-Questionnaire-Question-details-hint',
  },
  optionGroups: { wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-Question-optionGroups-wrapper' },
  actions: { wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-Question-actions-wrapper' },
} as const
export const wizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupNewInstancePromptClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-QuestionGroup-NewInstancePrompt-wrapper',
  actions: { wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-QuestionGroup-NewInstancePrompt-actions-wrapper' },
} as const
export const wizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-QuestionGroup-wrapper',
  newInstancePrompt: wizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupNewInstancePromptClasses,
} as const
export const wizardLayoutLeftPaneDocumentQuestionnaireClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Questionnaire-wrapper',
  questionGroup: wizardLayoutLeftPaneDocumentQuestionnaireQuestionGroupClasses,
  question: wizardLayoutLeftPaneDocumentQuestionnaireQuestionClasses,
  optionGroup: wizardLayoutLeftPaneDocumentQuestionnaireOptionGroupClasses,
  option: wizardLayoutLeftPaneDocumentQuestionnaireOptionClasses,
  questionOverview: wizardLayoutLeftPaneDocumentQuestionnaireQuestionOverviewClasses,
} as const
export const wizardLayoutLeftPaneDocumentConfigurationActionsClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Configuration-Actions-wrapper',
}
export const wizardLayoutLeftPaneDocumentConfigurationIntegrationsInstanceClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Configuration-Integrations-Integration-wrapper',
  title: 'WizardLayout-LeftPane-Document-Configuration-Integrations-Integration-title',
  actions: 'WizardLayout-LeftPane-Document-Configuration-Integrations-Integration-actions',
  entries: {
    wrapper: 'WizardLayout-LeftPane-Document-Configuration-Integrations-Integration-entries-wrapper',
    field: 'WizardLayout-LeftPane-Document-Configuration-Integrations-Integration-entries-field',
  },
}
export const wizardLayoutLeftPaneDocumentConfigurationIntegrationsClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Configuration-Integrations-wrapper',
  title: 'WizardLayout-LeftPane-Document-Configuration-Integrations-title',
  entries: 'WizardLayout-LeftPane-Document-Configuration-Integrations-entries',
  entry: 'WizardLayout-LeftPane-Document-Configuration-Integrations-entry',
}
export const wizardLayoutLeftPaneDocumentConfigurationLanguagesClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Configuration-Languages-wrapper',
  title: 'WizardLayout-LeftPane-Document-Configuration-Languages-title',
  entries: 'WizardLayout-LeftPane-Document-Configuration-Languages-entries',
  entry: 'WizardLayout-LeftPane-Document-Configuration-Languages-entry',
}
export const wizardLayoutLeftPaneDocumentConfigurationPaywallClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Configuration-Paywall-wrapper',
  title: 'WizardLayout-LeftPane-Document-Configuration-Paywall-title',
  description: 'WizardLayout-LeftPane-Document-Configuration-Paywall-description',
  subscriptions: {
    wrapper: 'WizardLayout-LeftPane-Document-Configuration-Paywall-subscriptions-wrapper',
    entry: 'WizardLayout-LeftPane-Document-Configuration-Paywall-subscriptions-entry',
  },
}
export const wizardLayoutLeftPaneDocumentConfigurationClasses = {
  wrapper: 'WizardLayout-LeftPane-Document-Configuration-wrapper',
  info: {
    wrapper: 'WizardLayout-LeftPane-Document-Configuration-info-wrapper',
    title: 'WizardLayout-LeftPane-Document-Configuration-info-title',
    description: 'WizardLayout-LeftPane-Document-Configuration-info-description',
  },
  settings: {
    wrapper: 'WizardLayout-LeftPane-Document-Configuration-settings-wrapper',
    paywall: wizardLayoutLeftPaneDocumentConfigurationPaywallClasses,
    languages: wizardLayoutLeftPaneDocumentConfigurationLanguagesClasses,
    integrations: wizardLayoutLeftPaneDocumentConfigurationIntegrationsClasses,
    actions: wizardLayoutLeftPaneDocumentConfigurationActionsClasses,
  },
} as const
export const wizardLayoutLeftPaneDocumentTemplateSelctionClasses = { wrapper: 'WizardLayout-LeftPane-Document-TemplateSelection-wrapper' } as const
export const wizardLayoutLeftPaneClasses = {
  wrapper: 'WizardLayout-LeftPane-wrapper',
  document: {
    templateSelection: wizardLayoutLeftPaneDocumentTemplateSelctionClasses,
    configuration: wizardLayoutLeftPaneDocumentConfigurationClasses,
    questionnaire: wizardLayoutLeftPaneDocumentQuestionnaireClasses,
    review: wizardLayoutLeftPaneDocumentReviewClasses,
  },
  // template: {},
} as const
// ========================================================================================================== //
//
// ========================================================================================================== //
// ================================================= HEADER ================================================= //
// ========================================================================================================== //
export const wizardLayoutHeaderDynamicButtonClasses = {
  wrapper: 'WizardLayout-Header-DynamicButton-wrapper',
  dot: 'WizardLayout-Header-DynamicButton-dot',
} as const
export const wizardLayoutHeaderClasses = {
  wrapper: 'WizardLayout-Header-wrapper',
  logo: 'WizardLayout-Header-logo',
  title: 'WizardLayout-Header-title',
  autoSaveSwitch: 'WizardLayout-Header-autoSaveSwitch',
  dynamicButton: wizardLayoutHeaderDynamicButtonClasses,
  close: 'WizardLayout-Header-close',
} as const
// ========================================================================================================== //
//
export const wizardLayoutClasses = {
  wrapper: 'WizardLayout-wrapper',
  header: wizardLayoutHeaderClasses,
  left: wizardLayoutLeftPaneClasses,
  right: wizardLayoutRightPaneClasses,
  // footer: wizardLayoutFooterClasses,
} as const //
//
// ========================================================================================================== //
// ========================================================================================================== //
// ========================================================================================================== //

export const WizardLayoutContext = React.createContext<boolean>(false)
type WizardLayoutContextProviderProps = PropsWithChildren<{ publicFlow: boolean }>
export const WizardLayoutContextProvider: FunctionComponent<WizardLayoutContextProviderProps> = ({ publicFlow, children }) => {
  return <WizardLayoutContext.Provider value={publicFlow}>{children}</WizardLayoutContext.Provider>
}

export const useWizardLayoutContext = () => {
  const currentWizardLayoutContext = useContext(WizardLayoutContext)
  if (currentWizardLayoutContext === undefined) {
    throw new Error('useWizardLayoutContext has to be used within <WizardLayoutContext.Provider>')
  }
  return currentWizardLayoutContext
}

export { WizardLayout, Header, LeftPane, RightPane, Footer, wizardLayoutClasses as classes }
export default WizardLayout
