import React, { FunctionComponent } from 'react'

import {
  WizardLayoutRightPaneEditorMarkerSegmentsParagraphProps,
  wizardLayoutRightPaneEditorMarkerSegmentsParagraphClasses as classes,
} from '../../../../..'

export const Paragraph: FunctionComponent<WizardLayoutRightPaneEditorMarkerSegmentsParagraphProps> = React.memo(
  ({ id, customStyle, styles, text }) => {
    // const { content, numberingSystem, numberingValue } = useParagraph(id, textChunks)
    const paragraphClasses = [classes.wrapper, customStyle, ...styles].filter(cn => cn)
    return (
      <pre
        id={id}
        className={paragraphClasses.join(' ')}
        // style={{ counterSet: numberingSystem && `${numberingSystem} ${numberingValue}` }}
      >
        {text}
      </pre>
    )
  }
)

Paragraph.displayName = 'WizardLayout-RightPane-Editor-Segment-Paragraph'

export default Paragraph
