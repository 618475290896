import ACTION_TYPES from '../../../actionTypes'
import {
  InitializeWizardPayload,
  UpdateWizardStatePayload,
  SetWizardTemplateIdPayload,
  UpdateWizardTitlePayload,
  UpdateWizardDescriptionPayload,
  ToggleDocumentLanguagePayload,
  ChooseIntegrationEntryPayload,
  NavigateQuestionnaireToPayload,
  AnswerWithOptionPayload,
  UnanswerOptionPayload,
  AddLanguagePayload,
  RemoveLanguagePayload,
  AddApproverPayload,
  RemoveApproverPayload,
  InstantiateIntegrationPayload,
  DisconnectIntegrationPayload,
  AddIntegrationFieldPayload,
  RemoveIntegrationFieldPayload,
  UpdateDocumentSplitPayload,
  RemoveDocumentSplitPayload,
  UpdateMarkerPayload,
  ConnectMarkerToValueSourcePayload,
  DisconnectMarkerFromValueSourcePayload,
  AddMarkerConditionalPayload,
  RemoveMarkerConditionalPayload,
  AddMarkerValueMapPayload,
  RemoveMarkerValueMapPayload,
  // AddMarkerModifierPayload,
  // RemoveMarkerModifierPayload,
  ToggleQuestionPrivacyPayload,
  ToggleQuestionConditionalLogicPayload,
  UpdateIntegrationFieldValuesPayload,
  ResetIntegrationFieldValuesPayload,
  ActivateSplitPayload,
  SetSignatureSecurityLevelPayload,
  AddQuestionGroupInstancePayload,
  ConnectMarkerForInstancingPayload,
  RemoveMarkerInstancingPayload,
  AddQuestionConditionGroupPayload,
  ToggleQuestionConditionGroupLogicPayload,
  RemoveQuestionConditionGroupPayload,
  AddQuestionConditionPayload,
  RemoveQuestionConditionPayload,
  UpdateQuestionConditionPayload,
} from './helpers'

export type ResetWizardAction = () => void
export type InitializeWizardAction = (payload: InitializeWizardPayload) => void
export type UpdateWizardStateAction = (payload: UpdateWizardStatePayload) => void
export type SetWizardTemplateIdAction = (payload: SetWizardTemplateIdPayload) => void
export type ResetWizardTemplateIdAction = () => void
export type UpdateWizardTitleAction = (payload: UpdateWizardTitlePayload) => void
export type UpdateWizardDescriptionAction = (payload: UpdateWizardDescriptionPayload) => void

// ================================================================= //
// ====================== DOCUMENT GENERATION ====================== //
// ================================================================= //
// ------------------------- CONFIGURATION ------------------------- //
export type ToggleDocumentLanguageAction = (payload: ToggleDocumentLanguagePayload) => void
export type ChooseIntegrationEntryAction = (payload: ChooseIntegrationEntryPayload) => void
export type UpdateIntegrationFieldValuesAction = (payload: UpdateIntegrationFieldValuesPayload) => void
export type ResetIntegrationFieldValuesAction = (payload?: ResetIntegrationFieldValuesPayload) => void
// ---------------------- QUESTION NAVIGATION ---------------------- //
export type ToggleShowExternalQuestionsAction = () => void
export type NavigateQuestionnaireForwardAction = () => void
export type NavigateQuestionnaireBackwardAction = () => void
export type NavigateQuestionnaireToAction = (payload: NavigateQuestionnaireToPayload) => void
// ---------------------- ANSWERING QUESTIONS ---------------------- //
export type AddQuestionGroupInstanceAction = (payload: AddQuestionGroupInstancePayload) => void
export type AnswerWithOptionAction = (payload: AnswerWithOptionPayload) => void
export type UnanswerOptionAction = (payload: UnanswerOptionPayload) => void
// ---------------------------- PREVIEW ---------------------------- //
export type ActivateSplitAction = (payload: ActivateSplitPayload) => void
// ================================================================= //
//
//
//
// ================================================================= //
// ====================== TEMPLATE AUTOMATION ====================== //
// ================================================================= //
// ---------------------------- LANGUAGE ---------------------------- //
export type AddLanguageAction = (payload: AddLanguagePayload) => void
export type RemoveLanguageAction = (payload: RemoveLanguagePayload) => void
// ---------------------------- APPROVAL ---------------------------- //
export type AddApproverAction = (payload: AddApproverPayload) => void
export type RemoveApproverAction = (payload: RemoveApproverPayload) => void
// -------------------------- INTEGRATIONS -------------------------- //
export type InstantiateIntegrationAction = (payload: InstantiateIntegrationPayload) => void
export type DisconnectIntegrationAction = (payload: DisconnectIntegrationPayload) => void
export type AddIntegrationFieldAction = (payload: AddIntegrationFieldPayload) => void
export type RemoveIntegrationFieldAction = (payload: RemoveIntegrationFieldPayload) => void
// ----------------------------- SPLIT ----------------------------- //
export type SplitDocumentAction = () => void
export type UpdateDocumentSplitAction = (payload: UpdateDocumentSplitPayload) => void
export type RemoveDocumentSplitAction = (payload: RemoveDocumentSplitPayload) => void
// --------------------------- SIGNATURE --------------------------- //
export type SetSignatureSecurityLevelAction = (payload: SetSignatureSecurityLevelPayload) => void
// ----------------------------- MARKER ----------------------------- //
export type UpdateMarkerAction = (payload: UpdateMarkerPayload) => void
export type ConnectMarkerToValueSourceAction = (payload: ConnectMarkerToValueSourcePayload) => void
export type DisconnectMarkerFromValueSourceAction = (payload: DisconnectMarkerFromValueSourcePayload) => void
export type AddMarkerConditionalAction = (payload: AddMarkerConditionalPayload) => void
export type RemoveMarkerConditionalAction = (payload: RemoveMarkerConditionalPayload) => void
export type ConnectMarkerForInstancingAction = (payload: ConnectMarkerForInstancingPayload) => void
export type RemoveMarkerInstancingAction = (payload: RemoveMarkerInstancingPayload) => void
export type AddMarkerValueMapAction = (payload: AddMarkerValueMapPayload) => void
export type RemoveMarkerValueMapAction = (payload: RemoveMarkerValueMapPayload) => void
// export type AddMarkerModifierAction = (payload: AddMarkerModifierPayload) => void
// export type RemoveMarkerModifierAction = (payload: RemoveMarkerModifierPayload) => void
// ---------------------------- QUESTION ---------------------------- //
export type ToggleQuestionPrivacyAction = (payload: ToggleQuestionPrivacyPayload) => void
export type ToggleQuestionConditionalLogicAction = (payload: ToggleQuestionConditionalLogicPayload) => void
export type AddQuestionConditionGroupAction = (payload: AddQuestionConditionGroupPayload) => void
export type ToggleQuestionConditionGroupLogicAction = (payload: ToggleQuestionConditionGroupLogicPayload) => void
export type RemoveQuestionConditionGroupAction = (payload: RemoveQuestionConditionGroupPayload) => void
export type AddQuestionConditionAction = (payload: AddQuestionConditionPayload) => void
export type UpdateQuestionConditionAction = (payload: UpdateQuestionConditionPayload) => void
export type RemoveQuestionConditionAction = (payload: RemoveQuestionConditionPayload) => void

const actionCreators = {
  resetWizard: () => ({ type: ACTION_TYPES.WIZARD.RESET }),
  initializeWizard: (payload: InitializeWizardPayload) => ({ type: ACTION_TYPES.WIZARD.INITIALIZED, payload }),
  updateWizardState: (payload: UpdateWizardStatePayload) => ({ type: ACTION_TYPES.WIZARD.STATE_UPDATED, payload }),
  setWizardTemplateId: (payload: SetWizardTemplateIdPayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_ID_SET, payload }),
  resetWizardTemplateId: () => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_ID_RESET }),
  updateWizardTitle: (payload: UpdateWizardTitlePayload) => ({ type: ACTION_TYPES.WIZARD.TITLE_UPDATED, payload }),

  // ================================================================= //
  // ====================== DOCUMENT GENERATION ====================== //
  // ================================================================= //
  //
  // ------------------------- CONFIGURATION ------------------------- //
  toggleDocumentLanguage: (payload: ToggleDocumentLanguagePayload) => ({
    type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.CONFIGURATION.LANGUAGE_TOGGLED,
    payload,
  }),
  chooseIntegrationEntry: (payload: ChooseIntegrationEntryPayload) => ({
    type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.CONFIGURATION.INTEGRATION_ENTRY_CHOSEN,
    payload,
  }),
  updateIntegrationFieldValues: (payload: UpdateIntegrationFieldValuesPayload) => ({
    type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.CONFIGURATION.INTEGRATION_FIELD_VALUES_UPDATED,
    payload,
  }),
  resetIntegrationFieldValues: (payload?: ResetIntegrationFieldValuesPayload) => ({
    type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.CONFIGURATION.INTEGRATION_FIELD_VALUES_RESET,
    payload,
  }),
  // ------------------------- QUESTIONNAIRE ------------------------- //
  toggleShowExternalQuestions: () => ({ type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.QUESTIONNAIRE.SHOW_EXTERNAL_TOGGLED }),
  navigateQuestionnaireForward: () => ({ type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.QUESTIONNAIRE.NAVIGATED_FORWARD }),
  navigateQuestionnaireBackward: () => ({ type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.QUESTIONNAIRE.NAVIGATED_BACKWARD }),
  navigateQuestionnaireTo: (payload: NavigateQuestionnaireToPayload) => ({
    type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.QUESTIONNAIRE.NAVIGATED_TO,
    payload,
  }),
  addQuestionGroupInstance: (payload: AddQuestionGroupInstancePayload) => ({
    type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.QUESTIONNAIRE.QUESTION_GROUP.INSTANCE_ADDED,
    payload,
  }),
  answerWithOption: (payload: AnswerWithOptionPayload) => ({
    type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.QUESTIONNAIRE.QUESTION.ANSWERED_WITH_OPTION,
    payload,
  }),
  unanswerOption: (payload: UnanswerOptionPayload) => ({
    type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.QUESTIONNAIRE.QUESTION.OPTION_UNANSWERED,
    payload,
  }),
  // ---------------------------- PREVIEW ---------------------------- //
  activateSplit: (payload: ActivateSplitPayload) => ({ type: ACTION_TYPES.WIZARD.DOCUMENT_GENERATION.PREVIEW.SPLIT_ACTIVATED, payload }),
  // ================================================================= //
  //
  //
  //
  // ================================================================= //
  // ====================== TEMPLATE AUTOMATION ====================== //
  // ================================================================= //
  updateWizardDescription: (payload: UpdateWizardDescriptionPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.DESCRIPTION_UPDATED,
    payload,
  }),
  // ---------------------------- LANGUAGE ---------------------------- //
  addLanguage: (payload: AddLanguagePayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.LANGUAGE.ADDED, payload }),
  removeLanguage: (payload: RemoveLanguagePayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.LANGUAGE.REMOVED, payload }),
  toggleLanguageSelect: () => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.LANGUAGE.SELECT_TOGGLED }),
  // ---------------------------- APPROVAL ---------------------------- //
  addApprover: (payload: AddApproverPayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.APPROVER.ADDED, payload }),
  removeApprover: (payload: RemoveApproverPayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.APPROVER.REMOVED, payload }),
  // -------------------------- INTEGRATIONS -------------------------- //
  instantiateIntegration: (payload: InstantiateIntegrationPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.INTEGRATION.INSTANTIATED,
    payload,
  }),
  disconnectIntegration: (payload: DisconnectIntegrationPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.INTEGRATION.DISCONNECTED,
    payload,
  }),
  addIntegrationField: (payload: AddIntegrationFieldPayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.INTEGRATION.FIELD_ADDED, payload }),
  removeIntegrationField: (payload: RemoveIntegrationFieldPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.INTEGRATION.FIELD_REMOVED,
    payload,
  }),
  // ----------------------------- SPLIT ----------------------------- //
  splitDocument: () => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.SPLIT.ADDED }),
  updateDocumentSplit: (payload: UpdateDocumentSplitPayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.SPLIT.UPDATED, payload }),
  removeDocumentSplit: (payload: RemoveDocumentSplitPayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.SPLIT.REMOVED, payload }),
  // --------------------------- SIGNATURE --------------------------- //
  setSignatureSecurityLevel: (payload: SetSignatureSecurityLevelPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.SIGNATURE.SECURITY_LEVEL_SET,
    payload,
  }),
  // ----------------------------- MARKER ----------------------------- //
  updateMarker: (payload: UpdateMarkerPayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.UPDATED, payload }),
  connectMarkerToValueSource: (payload: ConnectMarkerToValueSourcePayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.CONNECTED_TO_VALUE_SOURCE,
    payload,
  }),
  disconnectMarkerFromValueSource: (payload: ConnectMarkerToValueSourcePayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.DISCONNECTED_FROM_VALUE_SOURCE,
    payload,
  }),
  addMarkerConditional: (payload: AddMarkerConditionalPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.CONDITIONAL_ADDED,
    payload,
  }),
  removeMarkerConditional: (payload: RemoveMarkerConditionalPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.CONDITIONAL_REMOVED,
    payload,
  }),
  connectMarkerForInstancing: (payload: ConnectMarkerForInstancingPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.CONNECTED_FOR_INSTANCING,
    payload,
  }),
  removeMarkerInstancing: (payload: RemoveMarkerInstancingPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.INSTANCING_REMOVED,
    payload,
  }),
  addMarkerValueMap: (payload: AddMarkerValueMapPayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.VALUE_MAP_ADDED, payload }),
  removeMarkerValueMap: (payload: RemoveMarkerValueMapPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.VALUE_MAP_REMOVED,
    payload,
  }),
  // addMarkerModifier: (payload: AddMarkerModifierPayload) => ({ type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.MODIFIER_ADDED, payload }),
  // removeMarkerModifier: (payload: RemoveMarkerModifierPayload) => ({
  //   type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.MARKER.MODIFIER_REMOVED,
  //   payload,
  // }),
  // ---------------------------- QUESTION ---------------------------- //
  toggleQuestionPrivacy: (payload: ToggleQuestionPrivacyPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.QUESTION.PRIVACY_TOGGLED,
    payload,
  }),
  toggleQuestionConditionalLogic: (payload: ToggleQuestionConditionalLogicPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.QUESTION.CONDITIONAL.LOGIC_TOGGLED,
    payload,
  }),
  addQuestionConditionGroup: (payload: AddQuestionConditionGroupPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.QUESTION.CONDITIONAL.GROUP.ADDED,
    payload,
  }),
  toggleQuestionConditionGroupLogic: (payload: ToggleQuestionConditionGroupLogicPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.QUESTION.CONDITIONAL.GROUP.LOGIC_TOGGLED,
    payload,
  }),
  removeQuestionConditionGroup: (payload: RemoveQuestionConditionGroupPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.QUESTION.CONDITIONAL.GROUP.REMOVED,
    payload,
  }),
  addQuestionCondition: (payload: AddQuestionConditionPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.QUESTION.CONDITIONAL.GROUP.RULE.ADDED,
    payload,
  }),
  updateQuestionCondition: (payload: UpdateQuestionConditionPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.QUESTION.CONDITIONAL.GROUP.RULE.UPDATED,
    payload,
  }),
  removeQuestionCondition: (payload: RemoveQuestionConditionPayload) => ({
    type: ACTION_TYPES.WIZARD.TEMPLATE_AUTOMATION.QUESTION.CONDITIONAL.GROUP.RULE.REMOVED,
    payload,
  }),
}

export { actionCreators }
export default actionCreators
