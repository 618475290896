import React, { FunctionComponent, useMemo } from 'react'

import { ANSWER_VALUE_MATCH, OptionValueTypeUnionType, SEGMENT_TYPES, TextChunkObject, VALUE_SOURCE_TYPE_MATCH } from '___types'
import { formatMarkerValue, getFormattedOptionValue } from 'utilities/helpers'
import { MarkerInstanceContextProvider, useInstanceContext } from '.'
import { getSpanStyles, mapContentToSpan, parseChunkContent } from '../../helpers'
import { useTextMarker } from '../../customHooks'
import { WizardLayoutRightPaneEditorMarkerTextProps, wizardLayoutRightPaneEditorMarkerTextClasses as classes } from '../../../../..'
import { calculate, parseCalculation } from '___utilities'

export const Text: FunctionComponent<WizardLayoutRightPaneEditorMarkerTextProps> = React.memo(({ id, range, textChunks }) => {
  const {
    color,
    defaultKeep,
    optionIds,
    keep,
    replace,
    instancing,
    instanceCount,
    concatString,
    questionParents,
    optionParents,
    offsetMarkers,
    valueSources,
    combine,
    calculation,
    formatting,
  } = useTextMarker(id, range)
  const instanceContext = useInstanceContext()

  const render = useMemo(() => {
    const instances = new Array(instancing ? instanceCount : 1).fill(Object.assign({}, instanceContext)).map((context, i) => {
      if (instancing) Object.assign(context, { [instancing]: i })

      if (typeof keep === 'boolean') {
        if (!keep) return null
      } else {
        const relevantKeepValues = (keep as string[])?.reduce((result, value) => {
          const { source, instanceIndex } = value.match(ANSWER_VALUE_MATCH)?.groups || {}
          const relevantOptionConnection = optionIds.find(optionIdString => {
            const [id, instance] = optionIdString.split(':')
            if (id !== source) return false
            return instance === 'parent-instance'
              ? String(context[questionParents[optionParents[id]]] ?? 0) === instanceIndex
              : instance === instanceIndex
            // HANDLE OTHER INSTANCING CASES
          })
          return result.concat(relevantOptionConnection ? value : [])
        }, [] as string[])
        const hide = defaultKeep ? !relevantKeepValues.length : optionIds.length !== relevantKeepValues.length
        if (hide) return null
      }

      const valueSourceRelevantInstances = Object.entries(replace || {}).reduce((result, [valueSource]) => {
        let relevantInstance = 0
        const { id, instance } = valueSource.match(VALUE_SOURCE_TYPE_MATCH)?.groups || {}
        const questionGroupId = questionParents[id]
        if (instance === 'parent-instance' && questionGroupId) relevantInstance = context[questionGroupId] ?? 0
        // HANDLE OTHER INSTANCING CASES
        return Object.assign(result, { [valueSource]: String(relevantInstance) })
      }, {} as Record<string, string>)

      const replaceValues = [] as string[]

      if (combine && calculation && valueSources?.length) {
        const parsedCalculation = parseCalculation(calculation || '')
        const references = valueSources.map(
          source => replace?.[source][valueSourceRelevantInstances[source]]?.map(({ value }) => Number(value)) || []
        )
        replaceValues.push(...calculate(parsedCalculation, references).map(value => String(value)))
      } else
        replaceValues.push(
          ...Object.entries(replace || {}).reduce(
            (result, [valueSource, instanceValues]) =>
              result.concat(
                (instanceValues[valueSourceRelevantInstances[valueSource]] || []).map(({ type, value }) =>
                  String(getFormattedOptionValue(type as OptionValueTypeUnionType, value))
                )
              ),
            [] as string[]
          )
        )

      if (replaceValues.length) {
        const replaceString = replaceValues.join(concatString || ' - ')
        const chunk = {
          type: SEGMENT_TYPES.TEXT_CHUNK,
          text: formatting ? formatMarkerValue(replaceString, formatting) : replaceString,
          styles: textChunks ? getSpanStyles(textChunks) : [],
        } as TextChunkObject
        return [mapContentToSpan(chunk, id, i, 0, 'interact')]
      }
      const chunks = textChunks?.length ? parseChunkContent(offsetMarkers, textChunks) : []
      return chunks.map((chunk, index) => mapContentToSpan(chunk, id, i, index, 'interact'))
    })

    return instances
      .filter(c => c)
      .map((content, i) => (
        <span key={`TextMarker-${id}-instance-${i}`} className={classes.instance}>
          {instancing ? (
            <MarkerInstanceContextProvider groupId={instancing} index={i}>
              {content}
            </MarkerInstanceContextProvider>
          ) : (
            content
          )}
        </span>
      ))
  }, [
    instancing,
    instanceCount,
    instanceContext,
    keep,
    optionIds,
    questionParents,
    optionParents,
    defaultKeep,
    replace,
    combine,
    calculation,
    valueSources,
    concatString,
    formatting,
    textChunks,
    id,
    offsetMarkers,
  ])

  return (
    <mark id={id} className={classes.wrapper} data-discard={!render.length ? '' : undefined} data-color-variant={color}>
      {render}
    </mark>
  )
})

Text.displayName = 'WizardLayout-RightPane-Editor-Marker-Text'

export default Text
