import React, { FunctionComponent, useMemo } from 'react'
import useStore, {
  ConnectMarkerForInstancingAction,
  RemoveMarkerInstancingAction,
  WizardLocationByIdSelector,
  WizardQuestionLayoutSelector,
} from '___store'
import { Button } from 'components/CasusComponents'

type UseStoreHookResultType = {
  wizardQuestionLayout: WizardQuestionLayoutSelector
  wizardLocationById: WizardLocationByIdSelector
  connectMarkerForInstancing: ConnectMarkerForInstancingAction
  removeMarkerInstancing: RemoveMarkerInstancingAction
}
export const Instancing: FunctionComponent<{ id: string }> = React.memo(({ id }) => {
  const { wizardQuestionLayout, wizardLocationById, connectMarkerForInstancing, removeMarkerInstancing } = useStore(
    'selectWizardQuestionLayout',
    `selectWizardLocationById[${id}]`,
    'connectMarkerForInstancing',
    'removeMarkerInstancing'
  ) as UseStoreHookResultType

  const { instancing } = wizardLocationById || {}
  const questionGroups = useMemo(() => (wizardQuestionLayout || []).filter(({ type }) => type === 'group'), [wizardQuestionLayout])

  return (
    <div>
      {questionGroups.map(({ id: groupId, label }) => (
        <Button
          key={`Wizard-Configuration-MarkerConfiguration-Connect-Instancing-ConnectButton-${groupId}`}
          onClick={() => connectMarkerForInstancing({ id, groupId })}
          disabled={instancing === groupId}
          tertiary={instancing === groupId}
        >
          {label}
        </Button>
      ))}
      <Button onClick={() => removeMarkerInstancing(id)} red>
        Remove cloning
      </Button>
    </div>
  )
})

Instancing.displayName = 'Wizard-Configuration-MarkerConfiguration-Connect-Instancing'

export default Instancing
