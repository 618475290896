import useStore from '___store'
import { Button, Input, Switch } from 'components/CasusComponents'
import React, { FunctionComponent } from 'react'

export const QuestionGroupConfig: FunctionComponent<{ id: string }> = React.memo(({ id }) => {
  const { questionLayoutGroupById, updateQuestionLayoutGroup, concludeConfiguring } = useStore(
    `selectQuestionLayoutGroupById[${id}]`,
    'updateQuestionLayoutGroup',
    'concludeConfiguring'
  )

  return (
    <div>
      <Input
        type="text"
        value={questionLayoutGroupById?.affirmative ?? ''}
        onInput={event => updateQuestionLayoutGroup({ id, affirmative: (event.target as HTMLInputElement).value })}
      />
      <Input
        type="text"
        value={questionLayoutGroupById?.negative ?? ''}
        onInput={event => updateQuestionLayoutGroup({ id, negative: (event.target as HTMLInputElement).value })}
      />
      <Input
        type="text"
        value={questionLayoutGroupById?.instancePrompt ?? ''}
        onInput={event => updateQuestionLayoutGroup({ id, instancePrompt: (event.target as HTMLInputElement).value })}
      />
      <Switch value={questionLayoutGroupById.instantiable ?? false} onClick={instantiable => updateQuestionLayoutGroup({ id, instantiable })} />
      <Button onClick={concludeConfiguring} primary>
        Conclude
      </Button>
    </div>
  )
})

QuestionGroupConfig.displayName = ''

export default QuestionGroupConfig
