import React, { FunctionComponent } from 'react'

import { QuestionConditionSource, QuestionConditionRule } from '___types'
import { conditionClasses as classes } from '.'
import useStore, { RemoveQuestionConditionAction, UpdateQuestionConditionAction } from '___store'
import { Button, Select } from 'components/CasusComponents'
import { Cross } from 'assets/svgIconComponents'
import * as Sources from './ConditionSources'

type UseStoreHookResultType = { updateQuestionCondition: UpdateQuestionConditionAction; removeQuestionCondition: RemoveQuestionConditionAction }
export const QuestionCondition: FunctionComponent<{ questionId: string; groupId: string; id: string; condition: QuestionConditionRule }> = React.memo(
  ({ questionId, groupId, id, condition }) => {
    const { updateQuestionCondition, removeQuestionCondition } = useStore(
      'updateQuestionCondition',
      'removeQuestionCondition'
    ) as UseStoreHookResultType

    const { source = 'question' } = condition as { source: string }

    return (
      <div className={classes.wrapper}>
        <Select
          value={source}
          options={{ question: 'Question', marker: 'Marker' }}
          onSelect={source => updateQuestionCondition({ questionId, groupId, id, condition: { source: source as QuestionConditionSource } })}
        />
        {source === 'question' ? <Sources.Question questionId={questionId} groupId={groupId} id={id} condition={condition} /> : null}
        <Button onClick={() => removeQuestionCondition({ questionId, groupId, id })} red>
          <Cross />
        </Button>
      </div>
    )
  }
)

QuestionCondition.displayName = 'Question-ConditionalConfiguration-ConditionGroup-Condition'

export default QuestionCondition
