import React, { useMemo } from 'react'
import useStore from '___store'

import { Button } from 'components'
import { classes } from '../..'

export const AssignToOptionButton = React.memo(({ markerId, optionId }) => {
  const {
    locationById: { optionIds = [] } = {},
    optionById: { value, text, placeholder } = {},
    unassignMarker,
    assignMarker,
  } = useStore(`selectLocationById[${markerId}]`, `selectOptionById[${optionId}`, 'unassignMarker', 'assignMarker')
  const optionText = useMemo(() => text || value || placeholder || 'User input...', [text, value, placeholder])
  const assignedToOption = useMemo(() => optionIds.some(id => id.split(':')[0] === optionId.split(':')[0]), [optionIds, optionId])
  return (
    <Button
      className={classes.configEntry.markerConfiguration.fragment.assignToOptionButton}
      onClick={() =>
        assignedToOption
          ? unassignMarker({ markerId, optionId: `${optionId}:parent-instance` })
          : assignMarker({ markerId, optionId: `${optionId}:parent-instance` })
      }
      colorScheme="secondary"
    >
      {assignedToOption ? `Unassign from: ${optionText}` : `Assign to: ${optionText}`}
    </Button>
  )
})

export default AssignToOptionButton
