import React, { FunctionComponent, useMemo } from 'react'
import useStore, {
  WizardState,
  WizardDataStructureSelector,
  WizardNumberingSelector,
  WizardLocationsSelector,
  WizardQuestionsSelector,
  WizardLanguagesSelector,
  WizardIntegrationsSelector,
  WizardActiveSplitSelector,
  WizardInstancingSelector,
  WizardAnswersSelector,
  WizardQuestionLayoutSelector,
} from '___store'
import {
  FOUND_SEGMENT_REFERENCES,
  applyParagraphNumbering,
  extractDataStructureSections,
} from '___store/storeSegments/wizard/typified/helpers/editor-content'

import { DOCUMENT_FLOW_MODES } from '___types'
import { EditorPreviewContextProvider, rasterizeDataStructure } from '.'
import Section from './Preview.Components'
import { WizardLayoutRightPaneEditorPreviewProps, wizardLayoutRightPaneEditorPreviewClasses as classes } from '../../..'

type UseStoreHookResultType = {
  wizardDataStructure: WizardDataStructureSelector
  wizardLocations: WizardLocationsSelector
  wizardQuestions: WizardQuestionsSelector
  wizardQuestionLayout: WizardQuestionLayoutSelector
  wizardLanguages: WizardLanguagesSelector
  wizardIntegrations: WizardIntegrationsSelector
  wizardActiveSplit: WizardActiveSplitSelector
  wizardInstancing: WizardInstancingSelector
  wizardAnswers: WizardAnswersSelector
}

export const Preview: FunctionComponent<WizardLayoutRightPaneEditorPreviewProps> = React.memo(() => {
  const {
    wizardDataStructure,
    wizardLocations,
    wizardQuestions,
    wizardQuestionLayout,
    wizardLanguages,
    wizardIntegrations,
    wizardActiveSplit,
    wizardInstancing,
    wizardAnswers,
  } = useStore(
    'selectWizardDataStructure',
    'selectWizardLocations',
    'selectWizardQuestions',
    'selectWizardQuestionLayout',
    'selectWizardLanguages',
    'selectWizardIntegrations',
    'selectWizardActiveSplit',
    'selectWizardInstancing',
    'selectWizardAnswers'
  ) as UseStoreHookResultType

  const [dataStructure, numbering] = useMemo(() => {
    if (wizardDataStructure) {
      //@ts-ignore
      delete FOUND_SEGMENT_REFERENCES.preview
      const rasterizedDataStructure = rasterizeDataStructure(
        DOCUMENT_FLOW_MODES.PREVIEW,
        wizardDataStructure,
        wizardLocations,
        wizardQuestions,
        wizardQuestionLayout,
        wizardLanguages?.selected || [],
        wizardIntegrations,
        wizardActiveSplit,
        wizardInstancing,
        wizardAnswers
      )[0]
      Object.assign(rasterizedDataStructure, { sections: extractDataStructureSections(rasterizedDataStructure) })
      const pseudoState = { mode: DOCUMENT_FLOW_MODES.PREVIEW, dataStructure: rasterizedDataStructure } as WizardState
      return [rasterizedDataStructure, applyParagraphNumbering(pseudoState).dataStructure?.numbering as WizardNumberingSelector]
    }
    return [null, undefined]
  }, [
    wizardDataStructure,
    wizardLocations,
    wizardQuestions,
    wizardQuestionLayout,
    wizardLanguages,
    wizardIntegrations,
    wizardActiveSplit,
    wizardInstancing,
    wizardAnswers,
  ])

  const sections = useMemo(
    () =>
      new Array(wizardDataStructure?.sections?.length || 0)
        .fill(null)
        .map((_, i) => <Section key={`${DOCUMENT_FLOW_MODES.PREVIEW}-Section-${i}`} index={i}></Section>),
    [wizardDataStructure]
  )

  return (
    <div className={classes.wrapper}>
      <EditorPreviewContextProvider mode="preview" dataStructure={dataStructure} numbering={numbering}>
        <div className={classes.content}>{sections}</div>
      </EditorPreviewContextProvider>
    </div>
  )
})

Preview.displayName = 'WizardLayout-RightPane-Editor-Preview'

export default Preview
