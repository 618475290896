import { v4 as uuid } from 'uuid'
import { deepAssign, extractFromNestedStructure, filterObjectFields, fullAssign, generateKnownIds } from './index'
import { getSegmentById } from './editor-content'

// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// //////////////////////////////////////////////////////// HELPERS //////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //

// /////////////////////////////////////////////////// //
// ///////////////// QUESTION LAYOUT ///////////////// //
// /////////////////////////////////////////////////// //
const genericQuestionLayoutGroup = { id: '', type: 'group', label: 'New group', questions: [] }
const generateQuestionLayoutGroup = group => Object.assign({ ...genericQuestionLayoutGroup }, { id: uuid(), questions: [] }, group)
const genericLooseQuestionLayoutGroupConfiguration = { type: 'loose', label: 'loose' }
export const generateLooseQuestionLayoutGroup = group =>
  Object.assign(generateQuestionLayoutGroup(group), genericLooseQuestionLayoutGroupConfiguration)
const getQuestionLayoutGroup = (state, test, payload) => extractFromNestedStructure(state, ['questionLayout'], test, generateKnownIds(payload))
const getQuestionLayoutGroupById = (state, id, payload) => (id ? getQuestionLayoutGroup(state, group => group.id === id, payload) : [])
const groupByQuestionIdTest = id => group => group.questions.includes(id)
const getQuestionLayoutGroupByQuestionId = (state, id, payload) => (id ? getQuestionLayoutGroup(state, groupByQuestionIdTest(id), payload) : [])
const getFirstQuestionLayoutGroupOfType = (state, type, payload) => getQuestionLayoutGroup(state, group => group.type === type, payload)
const prependToQuestionLayout = (state, group) =>
  fullAssign({}, state, { questionLayout: [generateQuestionLayoutGroup(group)].concat(state.questionLayout) })
const appendToQuestionLayout = (state, group) =>
  fullAssign({}, state, { questionLayout: state.questionLayout.concat(generateQuestionLayoutGroup(group)) })
// /////////////////////////////////////////////////// //
// ///////////////////// OPTIONS ///////////////////// //
// /////////////////////////////////////////////////// //
const genericOption = { id: '', type: 'static', value: '', text: '', placeholder: 'Your input...', valueType: 'string', markers: [] }
const generateOption = option => Object.assign({ ...genericOption }, { id: uuid(), markers: [] }, option)
const getQuestionOption = (question, test, payload) =>
  extractFromNestedStructure(question, ['optionGroups', 'options'], test, generateKnownIds(payload))
const getOptionGroupOption = (optionGroup, test, payload) => extractFromNestedStructure(optionGroup, ['options'], test, generateKnownIds(payload))
const getOption = (state, test, payload) =>
  extractFromNestedStructure(state, ['questions', 'optionGroups', 'options'], test, generateKnownIds(payload))
const getOptionById = (state, id, payload) => (id ? getOption(state, option => option.id === id, payload) : [])
const getOptionGroupOptionById = (optionGroup, id, payload) => (id ? getOptionGroupOption(optionGroup, option => option.id === id, payload) : [])
export const getQuestionOptionById = (question, id, payload) => (id ? getQuestionOption(question, option => option.id === id, payload) : [])
// /////////////////////////////////////////////////// //
// ////////////////// OPTION GROUPS ////////////////// //
// /////////////////////////////////////////////////// //
const optionGroupSelect = { select: 'single', minimum: 0, maximum: 1, enforceLimit: true }
const genericOptionGroup = Object.assign({ id: '', label: '', options: [], valueType: 'string' }, optionGroupSelect)
const generateOptionGroup = group =>
  Object.assign({ ...genericOptionGroup }, { id: uuid(), options: [generateOption({ valueType: group?.valueType || 'string' })] }, group)
const getQuestionOptionGroup = (question, test, payload) => extractFromNestedStructure(question, ['optionGroups'], test, generateKnownIds(payload))
const getOptionGroup = (state, test, payload) => extractFromNestedStructure(state, ['questions', 'optionGroups'], test, generateKnownIds(payload))
const getOptionGroupById = (state, id, payload) => (id ? getOptionGroup(state, optionGroup => optionGroup.id === id, payload) : [])
const getQuestionOptionGroupById = (question, id, payload) =>
  id ? getQuestionOptionGroup(question, optionGroup => optionGroup.id === id, payload) : []
// /////////////////////////////////////////////////// //
// //////////////////// QUESTIONS //////////////////// //
// /////////////////////////////////////////////////// //
const questionDetails = { description: '', example: '', hint: '' }
const questionAdvanced = { visibility: 'show', logic: 'or', rules: [], subQuestionTo: '' }
const genericQuestion = Object.assign(
  { id: '', text: '', markers: [], optionGroups: [], valueType: 'string', advanced: questionAdvanced },
  questionDetails
)
const generateQuestionAdvanced = advanced => Object.assign({ ...questionAdvanced }, { rules: [] }, advanced)
const generateQuestion = question =>
  Object.assign(
    { ...genericQuestion },
    { id: uuid(), markers: [], optionGroups: [generateOptionGroup()], advanced: generateQuestionAdvanced() },
    question
  )
const getQuestion = (state, test, payload) => extractFromNestedStructure(state, ['questions'], test, generateKnownIds(payload))
export const getQuestionById = (state, id, payload) => (id ? getQuestion(state, question => question.id === id, payload) : [])
const appendToQuestions = (state, question) => fullAssign({}, state, { questions: state.questions.concat(question) })
// /////////////////////////////////////////////////// //
// ///////////////////// MARKERS ///////////////////// //
// /////////////////////////////////////////////////// //
const genericMarker = { id: '', label: '', color: 'variant-4', range: [], questionId: null, optionIds: [], defaultKeep: true }
const generateMarker = marker => Object.assign({ ...genericMarker }, { id: uuid(), range: new Array(2).fill(0), optionIds: [] }, marker)
const segmentsMarkerValues = { contentCustomStyle: 'Normal', contentStyles: [] }
const textMarkerValues = { contentText: '' }
const typeSpecificValues = { segments: segmentsMarkerValues, text: textMarkerValues }
const generateMarkerOfType = (type, marker) => generateMarker(Object.assign({ ...typeSpecificValues[type] }, marker))
const getMergedLocations = state => Object.assign({}, state.locations.segments, state.locations.text)
const getMarker = (state, test, marker = undefined, parentId = undefined, markerArray = undefined, index = -1) =>
  (Object.entries(getMergedLocations(state)).some(([id, locationArray]) =>
    locationArray?.some((location, i) => test(location) && (marker = location) && (parentId = id) && (markerArray = locationArray) && (index = i))
  ) ||
    true) && [marker, parentId, markerArray, index]
export const getMarkerById = (state, id) => (id ? getMarker(state, marker => marker.id === id) : [undefined, undefined, undefined, -1])
export const getContentParentIdByMarkerId = (state, id, found = undefined) =>
  ((found = Object.entries(getMergedLocations(state)).find(([_, locationArray]) => locationArray.find(({ id: markerId }) => markerId === id))) &&
    getContentParentIdByMarkerId(state, found[0])) ||
  id
// const getMarkerEntry = (state, test, parentId = undefined, markerArray = undefined) =>
//   (Object.entries(getMergedLocations(state)).some(
//     ([id, locationArray]) => test(id, locationArray) && (parentId = id) && (markerArray = locationArray)
//   ) ||
//     true) && [parentId, markerArray]
const getMarkerArrayByParentId = (state, id) => getMergedLocations(state)[id]
const filterTypeLocationArray = (state, type, test, update = false) => {
  const locationArray = Object.entries(state.locations[type])
  const filtered = locationArray.filter(([parentId, markerArray]) => !test(parentId, markerArray))
  return (locationArray.length !== filtered.length &&
    Object.assign(state.locations, {
      [type]: filtered.reduce((acc, [parentId, markerArray]) => Object.assign(acc, { [parentId]: markerArray }), {}),
    })) ||
    update
    ? Object.assign({}, state)
    : state
}
const filterLocationArray = (state, test) => filterTypeLocationArray(filterTypeLocationArray(state, 'text', test), 'segments', test)

// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //

// /////////////////////////////////////////////////// //
// ///////////////// QUESTION LAYOUT ///////////////// //
// /////////////////////////////////////////////////// //
export const mergeQuestionLayoutGroups = (state, update = false) => {
  const questionLayout = state.questionLayout.reduce((acc, cur) => {
    const lastGroup = acc[acc.length - 1]
    const questions = lastGroup?.questions?.concat(cur.questions) || cur.questions || []
    const check = cur.type !== 'loose' || !(lastGroup?.type === 'loose')
    return check ? acc.concat(cur) : (update = true) && acc.concat(Object.assign({}, acc.pop(), { questions }))
  }, [])
  return update ? fullAssign({}, state, { questionLayout }) : state
}
const removeEmptyLooseQuestionLayoutGroups = state => filterQuestionLayoutGroups(state, g => g.type === 'loose' && !g.questions?.length)
const tidyQuestionLayout = state => mergeQuestionLayoutGroups(removeEmptyLooseQuestionLayoutGroups(state))
const addQuestionToQuestionLayoutGroup = (state, id, groupId) => {
  const [group, index] = getQuestionLayoutGroupById(state, groupId)
  const questions = Array.from(new Set(group.questions.concat(id)))
  return group.questions.length !== questions.length && state.questionLayout.splice(index, 1, Object.assign({}, group, { questions }))
    ? Object.assign({}, state)
    : state
}
const filterQuestionLayoutGroups = (state, test, update = false) => {
  const questionLayout = state.questionLayout.filter(group => !test(group)) || []
  return (state.questionLayout.length !== questionLayout.length && Object.assign(state, { questionLayout })) || update
    ? Object.assign({}, state)
    : state
}
const filterQuestionFromQuestionLayout = (state, id) => {
  const [group, index] = getQuestionLayoutGroupByQuestionId(state, id)
  if (index === -1) return state
  const questions = group.questions.filter(questionId => questionId !== id)
  return group.questions.length !== questions.length && state.questionLayout.splice(index, 1, Object.assign({}, group, { questions }))
    ? Object.assign({}, state)
    : state
}
const updateQuestionLayoutGroup = (state, payload = {}) => {
  const [group, index] = getQuestionLayoutGroupById(state, payload?.id)
  return index === -1 ? state : state.questionLayout.splice(index, 1, Object.assign({}, deepAssign(group, payload))) && Object.assign({}, state)
}
// /////////////////////////////////////////////////// //
// //////////////////// QUESTIONS //////////////////// //
// /////////////////////////////////////////////////// //
const tidyQuestion = (question, update = false) => {
  if (!question.optionGroups?.length) update = (question.optionGroups = [generateOptionGroup()]) && true
  return [question, update]
}
// const tidyQuestions = (state, update = false) =>
//   state.questions.forEach(
//     (question, index, array) => (update = (tidyQuestion(question)[1] && Boolean(array.splice(index, 1, Object.assign({}, question)))) || update)
//   ) || update
//     ? Object.assign({}, state)
//     : state
const addNewQuestion = (state, payload = {}) => {
  const questionId = payload.id || uuid()
  const groupId = payload.questionLayoutGroup || uuid()
  const resultingState = appendToQuestionLayout(
    appendToQuestions(state, generateQuestion({ id: questionId })),
    generateLooseQuestionLayoutGroup({ id: groupId })
  )
  const questions = getQuestionLayoutGroupById(resultingState, groupId)[0].questions?.concat(questionId) || [questionId]
  return tidyQuestionLayout(updateQuestionLayoutGroup(resultingState, { id: groupId, questions }))
}
const filterQuestions = (state, test, update = false) => {
  const questions = state.questions.filter(question => !test(question)) || []
  return (state.questions.length !== questions.length && Object.assign(state, { questions })) || update ? Object.assign({}, state) : state
}
const filterQuestionsById = (state, id) => filterQuestions(state, question => question.id === id)
const removeQuestion = (state, payload = {}) => {
  const question = getQuestionById(state, payload.id)[0]
  return !question
    ? state
    : filterQuestionFromQuestionLayout(
        filterQuestionsById(
          question.optionGroups
            .reduce((acc, { options }) => acc.concat(options), [])
            .reduce(
              (acc, { id: optionId, markers }) =>
                (markers || []).reduce((currentState, markerId) => unassignMarker(currentState, { markerId, optionId }), acc),
              (question.markers || []).reduce((currentState, markerId) => unassignMarker(currentState, { markerId, questionId: payload.id }), state)
            ),
          payload.id
        ),
        payload.id
      )
}

export { addNewQuestion, removeQuestion }
// /////////////////////////////////////////////////// //
// ///////////////// ADVANCED CONFIG ///////////////// //
// /////////////////////////////////////////////////// //
const updateAdvancedQuestionConfiguration = (state, payload = {}) => {
  const question = getQuestionById(state, payload.id)[0]
  return !question
    ? state
    : Object.assign(question, { advanced: Object.assign({}, question.advanced, filterObjectFields(payload, 'id')) }) && Object.assign({}, state)
}
const makeIntoSubQuestion = (state, payload = {}) => {
  const subQuestion = getQuestionById(state, payload.id)[0]
  const parentQuestion = getQuestionById(state, payload.questionId)[0]
  const parentOptionGroup = getQuestionOptionGroupById(parentQuestion, payload.optionGroupId)[0]
  const parentOption = getOptionGroupOptionById(parentOptionGroup, payload.optionId)[0]
  if (!(subQuestion && parentOption)) return state
  const group = getFirstQuestionLayoutGroupOfType(state, 'sub-questions')[0]
  const groupId = group?.id || uuid()
  if (!group) prependToQuestionLayout(state, generateQuestionLayoutGroup({ id: groupId, type: 'sub-questions', label: 'Sub-questions' }))
  return updateAdvancedQuestionConfiguration(
    tidyQuestionLayout(addQuestionToQuestionLayoutGroup(filterQuestionFromQuestionLayout(state, subQuestion.id), subQuestion.id, groupId)),
    { id: subQuestion.id, subQuestionTo: `${parentQuestion.id}:${parentOptionGroup.id}:${parentOption.id}` }
  )
}
// const removeSubQuestionStatus = (state, payload = {}) => {
//   const { id } = getQbyID(state, payload.id)[0] || {}
//   return !id
//     ? state
//     : updateADV(cleanQL(appendToQL(filterQfromQLG(state, id), generateLooseQLG({ id: uuid(), questions: [id] }))), {
//         id,
//         subQuestionTo: '',
//       })
// }

export { updateAdvancedQuestionConfiguration, makeIntoSubQuestion }
// /////////////////////////////////////////////////// //
// ////////////////// OPTION GROUPS ////////////////// //
// /////////////////////////////////////////////////// //
const tidyOptionGroup = (optionGroup, update = false) => {
  if (!optionGroup.options?.length) update = (optionGroup.options = [generateOption()]) && true
  return [optionGroup, update]
}
// const tidyQuestionOptionGroups = (state, question, update = false) => {}
// const tidyOptionGroups = (state, update = false) => state.questions.forEach((question, index) => {})
const applyLimitsToPayload = (filteredPayload, optionGroup) => {
  const { select, minimum, maximum } = filteredPayload
  if (select === 'multi' && !(minimum || minimum === 0 || optionGroup.minimum)) Object.assign(filteredPayload, { minimum: 1 })
  if (select === 'multi' && !(maximum || maximum === 0 || optionGroup.maximum)) Object.assign(filteredPayload, { maximum: 1 })
  return filteredPayload
}
const addNewOptionGroup = (state, payload = {}) => {
  const [question, index] = getQuestionById(state, payload.questionId)
  return !question
    ? state
    : state.questions.splice(index, 1, Object.assign({}, question, { optionGroups: question.optionGroups.concat(generateOptionGroup()) })) &&
        Object.assign({}, state)
}
const updateOptionGroup = (state, payload = {}) => {
  const [optionGroup, index, question] = getOptionGroupById(state, payload.id, payload)
  if (!optionGroup) return state
  const resultingOptionGroup = Object.assign({}, optionGroup, applyLimitsToPayload(filterObjectFields(payload, 'id', 'questionId'), optionGroup))
  return question.optionGroups.splice(index, 1, resultingOptionGroup) && Object.assign({}, state)
}
const removeOptionGroup = (state, payload = {}) => {
  const [optionGroup, index, question, questionIndex] = getOptionGroupById(state, payload.id, payload)
  return !optionGroup
    ? state
    : question.optionGroups.splice(index, 1) &&
        tidyQuestion(question) &&
        state.questions.splice(questionIndex, 1, Object.assign({}, question)) &&
        Object.assign({}, state)
}
export { addNewOptionGroup, updateOptionGroup, removeOptionGroup }
// /////////////////////////////////////////////////// //
// ///////////////////// OPTIONS ///////////////////// //
// /////////////////////////////////////////////////// //
const tidyUpdateOptionPayload = (option, payload) => {
  const filteredPayload = filterObjectFields(payload, 'id', 'optionId', 'questionId')
  if ((filteredPayload.text && filteredPayload.text === (filteredPayload.value ?? option.value)) || filteredPayload.value === '')
    Object.assign(filteredPayload, { text: '' })
  return filteredPayload
}
const addNewOption = (state, payload = {}) => {
  const [optionGroup, index, question] = getOptionGroupById(state, payload.id, payload)
  if (!optionGroup) return state
  const resultingOption = generateOption(filterObjectFields(payload, 'id', 'questionId'))
  const resultingOptionGroup = Object.assign({}, optionGroup, { options: optionGroup.options.concat(resultingOption) })
  return question.optionGroups.splice(index, 1, resultingOptionGroup) && Object.assign({}, state)
}
const updateOption = (state, payload = {}) => {
  const [option, index, optionGroup] = getOptionById(state, payload.optionId, payload)
  return !option
    ? state
    : optionGroup.options.splice(index, 1, Object.assign({}, option, tidyUpdateOptionPayload(option, payload))) && Object.assign({}, state)
}
const removeOption = (state, payload = {}) => {
  const [option, index, optionGroup, optionGroupIndex, question] = getOptionById(state, payload.optionId, payload)
  return !option
    ? state
    : optionGroup.options.splice(index, 1) &&
        tidyOptionGroup(optionGroup) &&
        question.optionGroups.splice(optionGroupIndex, 1, Object.assign({}, optionGroup)) &&
        Object.assign({}, state)
}
export { addNewOption, updateOption, removeOption }
// /////////////////////////////////////////////////// //
// ///////////////////// MARKERS ///////////////////// //
// /////////////////////////////////////////////////// //
const tidyTypeLocations = (state, type, update = false) =>
  Object.entries(state.locations[type]).reduce(
    (acc, [parentId, markerArray]) => (markerArray.length ? Object.assign(acc, { [parentId]: markerArray }) : (update = true) && acc),
    {}
  ) && update
    ? Object.assign({}, state)
    : state
const tidyLocations = (state, update = false) => tidyTypeLocations(tidyTypeLocations(state, 'text', update), 'segments', update)
const unassignMarkerFromQuestionOption = (state, payload, marker, markerArray, markerIndex, update = false) => {
  const option = getOptionById(
    state,
    payload.optionId.split(':')[0]
    // payload // TODO: fix (optionId === {{id:instance}}) pattern for payload
  )[0]
  if (!option) return state
  const markers = option.markers.filter(id => id !== marker.id)
  update = (markers.length !== option.markers.length && Boolean(Object.assign(option, { markers }))) || update
  const optionIds = marker.optionIds.filter(id => id !== payload.optionId)
  update =
    (optionIds.length !== marker.optionIds.length && Boolean(markerArray.splice(markerIndex, 1, Object.assign({}, marker, { optionIds })))) || update
  return update ? Object.assign({}, state) : state
}
const unassignMarkerFromQuestion = (state, questionId, marker, markerArray, markerIndex, update = false) => {
  const question = getQuestionById(state, questionId)[0]
  if (!question) return state
  const markers = question.markers.filter(id => id !== marker.id)
  update = (markers.length !== question.markers.length && Boolean(Object.assign(question, { markers }))) || update
  update =
    (marker.questionId === questionId && Boolean(markerArray.splice(markerIndex, 1, Object.assign({}, marker, { questionId: null })))) || update
  return update ? Object.assign({}, state) : state
}
const unassignMarker = (state, payload) => {
  const [marker, parentId, markerArray, index] = getMarkerById(state, payload.markerId)
  if (!parentId) return state
  if (payload.optionId) return unassignMarkerFromQuestionOption(state, payload, marker, markerArray, index)
  if (payload.questionId) return unassignMarkerFromQuestion(state, payload.questionId, marker, markerArray, index)
  return state
}
const unassignMarkerFromEverything = (state, marker) =>
  marker.optionIds.reduce(
    (acc, optionId) => unassignMarker(acc, { markerId: marker.id, optionId }),
    marker.questionId ? unassignMarker(state, { markerId: marker.id, questionId: marker.questionId }) : state
  )
const removeLocation = (state, payload = {}) => {
  const [marker, parentId, markerArray] = getMarkerById(state, payload.id)
  if (!parentId) return state
  const resultingMarkerArray = markerArray.filter(m => m.id !== marker.id).concat(getMarkerArrayByParentId(state, marker.id) || [])
  const currentState = filterLocationArray(unassignMarkerFromEverything(state, marker), parentId => parentId === marker.id)
  return (
    Object.assign(currentState.locations[marker.type], { [parentId]: resultingMarkerArray.sort(({ range: [a] }, { range: [b] }) => a - b) }) &&
    tidyLocations(currentState, true)
  )
}
const assignMarkerToQuestionOption = (state, payload, marker, markerArray, markerIndex, update = false) => {
  const option = getOptionById(
    state,
    payload.optionId.split(':')[0]
    // payload // TODO: fix (optionId === {{id:instance}}) pattern for payload
  )[0]
  if (!option) return state
  const { markers: optionMarkers = [] } = option
  const markers = optionMarkers.includes(marker.id) ? optionMarkers : optionMarkers.concat(marker.id)
  update = (markers.length !== optionMarkers.length && Boolean(Object.assign(option, { markers }))) || update
  update =
    (!marker.optionIds.includes(payload.optionId) &&
      Boolean(markerArray.splice(markerIndex, 1, Object.assign({}, marker, { optionIds: marker.optionIds.concat(payload.optionId) })))) ||
    update
  return update ? Object.assign({}, state) : state
}
const assignMarkerToQuestion = (state, questionId, marker, markerArray, markerIndex, update = false) => {
  const question = getQuestionById(state, questionId)[0]
  if (!question) return state
  const resultingState = unassignMarkerFromQuestion(state, marker.questionId, marker, markerArray, markerIndex)
  const markers = question.markers.includes(marker.id) ? question.markers : question.markers.concat(marker.id)
  update = (markers.length !== question.markers.length && Boolean(Object.assign(question, { markers }))) || update
  update = (marker.questionId !== questionId && Boolean(markerArray.splice(markerIndex, 1, Object.assign({}, marker, { questionId })))) || update
  return update ? Object.assign({}, resultingState) : resultingState
}
const assignMarkerToIntegration = (state, integrationId, marker, markerArray, markerIndex, update = false) => {
  const resultingState = unassignMarkerFromQuestion(state, marker.questionId, marker, markerArray, markerIndex)
  update =
    (marker.questionId !== integrationId && Boolean(markerArray.splice(markerIndex, 1, Object.assign({}, marker, { questionId: integrationId })))) ||
    update
  return update ? Object.assign({}, resultingState) : resultingState
}
const assignMarker = (state, payload) => {
  const [marker, parentId, markerArray, index] = getMarkerById(state, payload.markerId)
  if (!parentId) return state
  if (payload.optionId) return assignMarkerToQuestionOption(state, payload, marker, markerArray, index)
  if (payload.questionId) return assignMarkerToQuestion(state, payload.questionId, marker, markerArray, index)
  if (payload.integrationId) return assignMarkerToIntegration(state, payload.integrationId, marker, markerArray, index)
  return state
}
// const reassignOptions = (state, marker, markerId) =>
//   marker.optionIds.reduce((acc, optionId) => assignMarker(unassignMarker(acc, { markerId: marker.id, optionId }), { markerId, optionId }), state)
// const reassignQuestion = (state, marker, markerId) =>
//   marker.questionId
//     ? assignMarker(unassignMarker(state, { markerId: marker.id, questionId: marker.questionId }), { markerId, questionId: marker.questionId })
//     : state
// const reassignQuestionAndOptions = (state, marker, markerId) => reassignOptions(reassignQuestion(state, marker, markerId), marker, markerId)
// const unassignMarkerArray = (state, markerArray) =>
//   markerArray.reduce((accumulated, marker) => unassignMarkerFromEverything(accumulated, marker), state)
// const removeMarkerArrayLocations = (state, markerArray) => markerArray.reduce((accumulated, { id }) => removeLocation(accumulated, { id }), state)
// const discardMarkerBuffer = (state, buffer) =>
//   Object.entries(buffer).reduce(
//     (acc, [parentId, markerArray]) =>
//       markerArray.length === 1
//         ? removeLocation(reassignQuestionAndOptions(acc, markerArray[0], parentId), { id: markerArray[0].id })
//         : removeMarkerArrayLocations(acc, markerArray),
//     state
//   )
const applyMarkerBuffer = (state, buffer) =>
  Object.entries(buffer).reduce(
    (acc, [parentId, markerArray]) =>
      markerArray.reduce((accumulated, marker) => addNewLocation(accumulated, Object.assign(marker, { parentId })), acc),
    state
  )
const assignResultingMarkerArray = (state, type, parentId, array) =>
  Object.assign(state.locations[type], { [parentId]: array.sort(({ range: [a] }, { range: [b] }) => a - b) }) && state
// const handleMarkerBuffer = (state, type, parentId, result, buffer) =>
//   (type === 'segments' && assignResultingMarkerArray(applyMarkerBuffer(state, buffer), type, parentId, result)) ||
//   (type === 'text' && discardMarkerBuffer(state, buffer)) ||
//   state
const findEncompassingRangeId = (markers, range, id = undefined) =>
  markers.find(marker => {
    const point = Math.sign(marker.range[0] + marker.range[1] - (range[0] + range[1]) + 0.5)
    const index = Math.abs(Math.ceil(point * -0.5))
    return Math.sign(range[index] - marker.range[index] + point) === point && (id = marker.id)
  }) && id
const unnestMarkers = markerArray =>
  markerArray.reduce(
    ({ result, buffer }, cur) => {
      const id = findEncompassingRangeId(result, cur.range)
      return (id ? Object.assign(buffer, { [id]: (buffer[id] || []).concat(Object.assign({}, cur)) }) : result.push(cur)) && { result, buffer }
    },
    { result: [], buffer: {} }
  )
const tidyMarkerArray = (state, type, parentId, update = false) => {
  const markerArray = getMarkerArrayByParentId(state, parentId)
  if (!markerArray) return state
  const { result, buffer } = unnestMarkers(markerArray)
  update = update || Boolean(Object.keys(buffer).length)
  // return update ? Object.assign({}, handleMarkerBuffer(state, type, parentId, result, buffer)) : state
  return update ? Object.assign({}, assignResultingMarkerArray(applyMarkerBuffer(state, buffer), type, parentId, result)) : state
}
const addNewLocation = (state, payload = {}) => {
  const { type, parentId } = payload
  const additionalValues = {}
  if (type === 'text') {
    const contentText = (getSegmentById(state, parentId)[0] || {}).textChunks
      ?.reduce((acc, { text }, i) => acc + (i ? ' ' : '') + text, '')
      .slice(...payload.range)
    Object.assign(additionalValues, { contentText, concatString: ' - ' })
  }
  const resultingMarkerArray = (getMarkerArrayByParentId(state, parentId) || [])
    .concat(generateMarkerOfType(type, Object.assign(additionalValues, filterObjectFields(payload, 'parentId'))))
    .sort(({ range: ar }, { range: br }) => br[1] - br[0] - (ar[1] - ar[0]))
  return (
    Object.assign(state.locations[type], { [parentId]: resultingMarkerArray.sort(({ range: [a] }, { range: [b] }) => a - b) }) &&
    tidyMarkerArray(Object.assign({}, state), type, parentId)
  )
}
export { unassignMarker, removeLocation, assignMarker, addNewLocation }
// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //
// /////////////////////////////////////////////////// //

const reorderQuestion = (state, payload) => {
  const { id, direction } = payload

  const [questionLayoutGroup] = getQuestionLayoutGroupByQuestionId(state, id)
  const { type, questions } = questionLayoutGroup

  const currentIndex = questions?.indexOf(id) ?? -1
  if (currentIndex === -1 || !['group', 'loose'].includes(type)) return state

  questions.splice(currentIndex, 1)

  let insertArray = questions
  let newIndex = currentIndex

  if (type === 'group' || currentIndex !== questions.length * direction) {
    newIndex = currentIndex - 1 + direction * 2
  } else {
    const looseGroups = state.questionLayout.filter(group => group.type === 'loose')
    const groupIndex = looseGroups.findIndex(group => group.id === questionLayoutGroup.id)
    if (groupIndex !== (looseGroups.length - 1) * direction) {
      const groupArray = looseGroups.map(group => [group.id, group.questions])
      insertArray = groupArray[Math.min(Math.max(0, groupIndex - 1 + direction * 2), looseGroups.length - 1)][1]
      newIndex = insertArray.length * Math.abs(direction - 1)
    }
  }

  insertArray.splice(Math.min(Math.max(0, newIndex), insertArray.length), 0, id)

  return Object.assign({}, state)
}

export { reorderQuestion }
