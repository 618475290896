import React, { useMemo } from 'react'
import useStore from '___store'

// import { Cross, VerticalEllipsis } from 'assets/svgIconComponents'
// import { Button, Input, Select } from 'components'
import { VerticalEllipsis } from 'assets/svgIconComponents'
import { Input, Select } from 'components'
import { ActionButton, QuestionListItem } from './components'
import { classes } from '../../../index'

const QuestionLayoutGroup = React.memo(({ id }) => {
  const {
    questionLayoutGroupType: type,
    questionLayoutGroupLabel: label,
    questionLayoutGroupQuestionIds,
    updateQuestionLayoutGroup,
    // removeQuestionLayoutGroup,
  } = useStore(
    `selectQuestionLayoutGroupType[${id}]`,
    `selectQuestionLayoutGroupLabel[${id}]`,
    `selectQuestionLayoutGroupQuestionIds[${id}]`,
    'updateQuestionLayoutGroup',
    'removeQuestionLayoutGroup'
  )

  const questionIds = useMemo(() => questionLayoutGroupQuestionIds.split('; ').filter(id => id), [questionLayoutGroupQuestionIds])
  const questions = useMemo(() => questionIds.map(qid => <QuestionListItem key={`group-${id}-question-${qid}`} id={qid} />), [questionIds, id])

  if (type === 'sub-questions') return null

  if (type === 'loose') return questions

  const {
    wrapper: wrapperClass,
    header: headerClass,
    input: inputClass,
    // button: buttonClass,
    actionSelect: actionSelectClass,
    content: contentClass,
  } = classes.configEntry.questionGeneration.group

  return (
    <div className={wrapperClass} data-group-type={type}>
      {/* <div className={headerClass} style={{ '--group-label': `"${label || id}"` || 'No label...' }}> */}
      <div className={headerClass}>
        <Input
          className={inputClass}
          placeholder={`${type?.charAt(0).toUpperCase()}${type?.slice(1)} label...`}
          defaultValue={label || id}
          needsConfirmation
          onChange={value => updateQuestionLayoutGroup({ id, label: value })}
          blurShowUnmodified
        />
        {/* {type === 'separator' ? (
          <Button className={buttonClass} onClick={() => removeQuestionLayoutGroup({ id })} colorScheme="transparent">
            <Cross />
          </Button>
        ) : ( */}
        <Select
          className={actionSelectClass}
          options={{ removeGroup: null, unpackGroup: null, addNewQuestion: null, configureGroup: null }}
          render={key => <ActionButton key={`QuestionLayoutGroup-${id}-ActionSelect-${key}`} button={key} groupId={id} />}
          chooseMode
          chooseRender={<VerticalEllipsis />}
          noCaret
          directionPriority="horizontal"
          horizontalDirection="left"
          cornerAnchor={false}
        />
        {/* )} */}
      </div>
      {type === 'group' ? <div className={contentClass}>{questions}</div> : null}
    </div>
  )
})

QuestionLayoutGroup.displayName = 'Wizard-Configuration-QuestionGeneration-QuestionLayoutGroup'

export default QuestionLayoutGroup
