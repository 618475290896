import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { IDocument, ITemplate, PartialDocument } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { generateDocumentProgress } from '.'
import { useCallback } from 'react'

export type UpdateDocumentVariables = { document: PartialDocument; publicFlow?: boolean }
export type UpdateDocumentContext = { mutationId: string }
export type UpdateDocumentMutationOptions = MutateOptions<IDocument | void, unknown, UpdateDocumentVariables, UpdateDocumentContext>
export type UpdateDocumentFunctionType = (document: PartialDocument, options?: UpdateDocumentMutationOptions) => void
const updateDocumentMutationFunction = (variables: UpdateDocumentVariables) => documentsAPI.updateDocument(variables.document, variables.publicFlow)

export const useUpdateDocument = (id?: string | null, publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const documentQueryKey = [QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : [])

  const updateListing = (method: (data: (IDocument & { mutationId?: string })[] | undefined) => IDocument[]) =>
    queryClient.setQueryData([QUERY_KEYS.DOCUMENTS], method)

  const onMutate = (variables: UpdateDocumentVariables) => {
    const mutationId = uuid()
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original?: IDocument }
    if (currentDocument) {
      const originalDocument = currentDocument.original || currentDocument
      const optimisticDocument = Object.assign({}, originalDocument, variables.document, {
        mutationId,
        mutating: true,
        mutation: 'update',
        original: originalDocument,
      })
      queryClient.setQueryData(documentQueryKey, optimisticDocument)
      if (!publicFlow) updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticDocument))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: UpdateDocumentVariables, context: UpdateDocumentContext | undefined) => {
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) {
      queryClient.removeQueries(documentQueryKey)
      queryClient.resetQueries(documentQueryKey)
      // queryClient.setQueryData(documentQueryKey, currentDocument.original)
      if (!publicFlow) {
        queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
        queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
        queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
        // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentDocument.original))
      }
    }
  }

  const onSuccess = (document: IDocument | void, variables: UpdateDocumentVariables, context: UpdateDocumentContext | undefined) => {
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) queryClient.setQueryData(documentQueryKey, document)
    if (!publicFlow)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, document))
    queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
    queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
    queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
  }

  const documentUpdateMutation = useMutation<IDocument | void, unknown, UpdateDocumentVariables, UpdateDocumentContext>(
    documentQueryKey,
    updateDocumentMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const updateMutationFunction: UpdateDocumentFunctionType = useCallback(
    (document, options) => {
      const originalDocument = queryClient.getQueryData(documentQueryKey) as IDocument
      const template = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, originalDocument.templateId].concat(publicFlow ? 'public' : [])) as ITemplate
      if (template && document.answers)
        Object.assign(document, {
          progress: generateDocumentProgress(
            template.questionLayout,
            document.instancing ?? originalDocument.instancing,
            template.questions,
            document.answers
          ),
        })
      return documentUpdateMutation.mutate({ document: Object.assign({}, document, { id: id! }), publicFlow }, options)
    },
    [queryClient, documentQueryKey, documentUpdateMutation, id, publicFlow]
  )

  return { update: updateMutationFunction, updating: documentUpdateMutation.isLoading }
}

export default useUpdateDocument
