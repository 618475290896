import React, { FunctionComponent, useMemo } from 'react'
import useStore, { WizardIntegrationsSelector } from '___store'

import Integration from './Available.Integration'

type UseStoreHookResultType = { wizardIntegrations: WizardIntegrationsSelector }
//  Apply proper type after creating a .ts selector

type Props = { id: string; valueSources: string[] }
export const Integrations: FunctionComponent<Props> = React.memo(({ id, valueSources }) => {
  const { wizardIntegrations } = useStore('selectWizardIntegrations') as UseStoreHookResultType

  const availableIntegrations = useMemo(
    () =>
      (wizardIntegrations ? Object.entries(wizardIntegrations) : []).map(([integrationId, { cue, fields }]) => (
        <Integration
          key={`Available-Value-Source-Set-${integrationId}`}
          id={id}
          valueSources={valueSources}
          integrationId={integrationId}
          integrationLabel={cue}
          fields={fields}
        />
      )),
    [wizardIntegrations, id, valueSources]
  )

  return <>{availableIntegrations}</>
})

Integrations.displayName = ''

export default Integrations
