import React, { FunctionComponent, useMemo } from 'react'

import { ANSWER_VALUE_MATCH, LOCATION_TYPES, OptionValueTypeUnionType, VALUE_SOURCE_TYPE_MATCH } from '___types'
import { formatMarkerValue, getFormattedOptionValue } from 'utilities/helpers'
import { MarkerInstanceContextProvider, useInstanceContext } from '.'
import { Paragraph } from './Paragraph'
import Marker from '../Marker'
import Segment from '../Segment'
import { useSegmentsMarker } from '../../customHooks'
import { WizardLayoutRightPaneEditorMarkerSegmentsProps, wizardLayoutRightPaneEditorMarkerSegmentsClasses as classes } from '../../../../..'
import { calculate, parseCalculation } from '___utilities'

// WARNING!
// SEGMENTS MARKERS SPANNING MULTIPLE PAGES MIGHT HAVE THE SAME ID! - fix if the issue arises!

export const Segments: FunctionComponent<WizardLayoutRightPaneEditorMarkerSegmentsProps> = React.memo(({ id, parent }) => {
  const {
    start,
    end,
    // color,
    contentCustomStyle,
    contentStyles,
    contentIds,
    defaultKeep,
    optionIds,
    keep,
    replace,
    instancing,
    instanceCount,
    questionParents,
    optionParents,
    valueSources,
    combine,
    calculation,
    formatting,
  } = useSegmentsMarker(id, parent)
  const instanceContext = useInstanceContext()

  const render = useMemo(
    () =>
      new Array(instancing ? instanceCount : 1)
        .fill(Object.assign({}, instanceContext))
        .map((context, i) => {
          if (instancing) Object.assign(context, { [instancing]: i })

          if (typeof keep === 'boolean') {
            if (!keep) return null
          } else {
            const relevantKeepValues = (keep as string[])?.reduce((result, value) => {
              const { source, instanceIndex } = value.match(ANSWER_VALUE_MATCH)?.groups || {}
              const relevantOptionConnection = optionIds.find(optionIdString => {
                const [id, instance] = optionIdString.split(':')
                if (id !== source) return false
                return instance === 'parent-instance'
                  ? String(context[questionParents[optionParents[id]]] ?? 0) === instanceIndex
                  : instance === instanceIndex
                // HANDLE OTHER INSTANCING CASES
              })
              return result.concat(relevantOptionConnection ? value : [])
            }, [] as string[])
            const hide = defaultKeep ? !relevantKeepValues.length : optionIds.length !== relevantKeepValues.length
            if (hide) return null
          }

          const valueSourceRelevantInstances = Object.entries(replace || {}).reduce((result, [valueSource]) => {
            let relevantInstance = 0
            const { id, instance } = valueSource.match(VALUE_SOURCE_TYPE_MATCH)?.groups || {}
            const questionGroupId = questionParents[id]
            if (instance === 'parent-instance' && questionGroupId) relevantInstance = context[questionGroupId] ?? 0
            // HANDLE OTHER INSTANCING CASES
            return Object.assign(result, { [valueSource]: String(relevantInstance) })
          }, {} as Record<string, string>)

          const replaceValues = [] as string[]

          if (combine) {
            const parsedCalculation = parseCalculation(calculation || '')
            const references = valueSources.map(
              source => replace?.[source][valueSourceRelevantInstances[source]]?.map(({ value }) => Number(value)) || []
            )
            replaceValues.push(...calculate(parsedCalculation, references).map(value => String(value)))
          } else
            replaceValues.push(
              ...Object.entries(replace || {}).reduce(
                (result, [valueSource, instanceValues]) =>
                  result.concat(
                    (instanceValues[valueSourceRelevantInstances[valueSource]] || []).map(({ type, value }) =>
                      String(getFormattedOptionValue(type as OptionValueTypeUnionType, value))
                    )
                  ),
                [] as string[]
              )
            )

          if (replaceValues.length)
            return replaceValues.map((value, index) => (
              <Paragraph
                key={`SegmentsMarker-${id}-${i}-list-paragraph-${index}`}
                id={`${id}-${i}`}
                customStyle={contentCustomStyle}
                styles={contentStyles}
                text={formatting ? formatMarkerValue(value, formatting) : value}
              />
            ))

          return contentIds?.map(id => {
            if (id.slice(0, 7) === 'marker:') return <Marker key={id} id={id.slice(7)} type={LOCATION_TYPES.SEGMENTS} parent={parent} />
            return <Segment key={id} id={id} />
          })
        })
        .filter(c => c)
        .map((content, i) => (
          <div key={`SegmentsMarker-${id}-instance-${i}`} className={classes.instance}>
            {instancing ? (
              <MarkerInstanceContextProvider groupId={instancing} index={i}>
                {content}
              </MarkerInstanceContextProvider>
            ) : (
              content
            )}
          </div>
        )),
    [
      instancing,
      instanceCount,
      instanceContext,
      keep,
      optionIds,
      questionParents,
      optionParents,
      defaultKeep,
      replace,
      combine,
      calculation,
      valueSources,
      contentCustomStyle,
      contentStyles,
      formatting,
      contentIds,
      parent,
      id,
    ]
  )

  return (
    <div
      id={`${start ? ';' : ''}${id}${end ? ';' : ''}`}
      className={classes.wrapper}
      data-start={start || undefined}
      data-end={end || undefined}
      data-discard={!render.length ? '' : undefined}
    >
      {render}
    </div>
  )
})

Segments.displayName = 'WizardLayout-RightPane-Editor-Marker-Segments'

export default Segments
