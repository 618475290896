import React, { FunctionComponent, PropsWithChildren, useContext } from 'react'

import Text from './Text'
import Segments from './Segments'
import Paragraph from './Paragraph'

export { Text, Segments, Paragraph }

export const MarkerInstanceContext = React.createContext<Record<string, number>>({})

type MarkerInstanceContextProviderProps = PropsWithChildren<{ groupId: string; index: number }>

export const MarkerInstanceContextProvider: FunctionComponent<MarkerInstanceContextProviderProps> = ({ groupId, index, children }) => {
  const instances = useInstanceContext()
  return <MarkerInstanceContext.Provider value={Object.assign({}, instances, { [groupId]: index })}>{children}</MarkerInstanceContext.Provider>
}

export const useInstanceContext = () => {
  const currentMarkerInstanceContext = useContext(MarkerInstanceContext)
  return currentMarkerInstanceContext ?? {}
}
